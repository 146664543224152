/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";

// Material Dashboard 2 React components

// Overview page components
import Header from "./components/Header";

// Images
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import PageLayout from "examples/LayoutContainers/PageLayout";
import FAB from "layouts/FAB";
import { Card, CircularProgress, Divider } from "@mui/material";
import "react-image-gallery/styles/css/image-gallery.css";
import GanttChart from "layouts/OngoingProjectProfile/components/ganttChart";
import CountContent from "./components/CountContent";

import districtImg from "assets/images/navigation/finance.png";

import be from "assets/images/bettericon/be.svg";
import bl from "assets/images/bettericon/bl.svg";
import bn from "assets/images/bettericon/bn.svg";
import bp from "assets/images/bettericon/bp.svg";
import ProfileInfoImgCard from "examples/Cards/ProfileInfoImgCard";
import { navActions } from "components/FaoNavigation/FaoNavigation";
import MDButton from "components/MDButton";

function ProjectProfile() {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState({
    id: null,
    entityNumber: null,
    projectSymbol: null,
    projectTitle: null,
    projectName: null,
    projectStatusCode: null,
    actualEOD: null,
    actualNTE: null,
    totalBudgetFPMIS: null,
    totalCashReceived: null,
    totalDelivery: null,
    donorAndFundingSource: null,
    countryName: null,
    strategicFrameworkOrganizationResult: null,
    strategicFrameworkStrategicObjective: null,
    strategicFrameworkUnitResult: null,
    projectObjectives: null,
    projectStaffBudgetHolder: null,
    projectStaffLTOOfficer: null,
    projectStaffLTUOfficer: null,
    projectStaffFundingOfficer: null,
    projectManager: null,
    projectCoordinator: null,
    communicationMaterials: null,
    districtList: null,
    districtsAndActivity: null,
    deliveryEstimateDataList: null,
    sdgList: null,
    deliverables: null,
  });
  const [mediaData, setMediaData] = useState([]);
  const [youtubeData, setYoutubeData] = useState([]);
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [betterInfo, setBetterInfo] = useState({
    image: "",
    entityNumber: "",
    code: "",
    description: "",
    ppaList: "",
  });

  useEffect(() => {
    const fetchEmergency = async () => {
      const controller = new AbortController();
      try {
        setLoading(true);
        const response = await axiosPrivate.get(`project-dashboard/emergency/projects/${id}`, {
          headers: {
            signal: controller.signal,
          },
        });
        setData(response.data);
        setMediaData(response.data?.communicationMaterials);
        setYoutubeData(
          response.data?.communicationMaterials?.filter((item) => item.category === "Youtube video")
        );
        setSocialMediaData(
          response.data?.communicationMaterials?.filter((item) => item.category === "Social media")
        );

        youtubeData?.map((item) => {
          let url = item.link;

          if (url.includes("youtube.com")) {
            url = url.replace("watch?v=", "embed/");
          }
          item.link = url;
        });

        setBetterInfo(
          getFourBetterIcon(
            response.data?.strategicFrameworkStrategicObjective,
            response.data?.strategicFrameworkOrganizationResult,
            response.data?.entityNumber
          )
        );

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchEmergency();
  }, []);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function getFourBetterIcon(strategicFrameworkStrategicObjective, ppaInfoList, entityNumber) {
    switch (strategicFrameworkStrategicObjective) {
      case "BE":
        return {
          image: be,
          entityNumber: entityNumber,
          code: "Better Environment",
          description:
            "Protect, restore and promote sustainable use of terrestrial and marine ecosystems and combat climate change (reduce, reuse, recycle, residual management) through more efficient, inclusive, resilient and sustainable agri-food systems",
          ppaList: ppaInfoList,
        };
      case "BL":
        return {
          image: bl,
          code: "Better Life",
          entityNumber: entityNumber,
          description:
            "Promote inclusive economic growth by reducing inequalities (urban/rural areas, rich/poor countries, men/women)",
          ppaList: ppaInfoList,
        };
      case "BN":
        return {
          image: bn,
          code: "Better Nutrition",
          entityNumber: entityNumber,
          description:
            "End hunger, achieve food security and improved nutrition in all its forms, including promoting nutritious food and increasing access to healthy diets",
          ppaList: ppaInfoList,
        };
      case "BP":
        return {
          image: bp,
          code: "Better Production",
          entityNumber: entityNumber,
          description:
            "Ensure sustainable consumption and production patterns, through efficient and inclusive food and agriculture supply chains at local, regional and global level, ensuring resilient and sustainable agri-food systems in a changing climate and environment",
          ppaList: ppaInfoList,
        };
      default:
        return {
          image: bp,
          code: "Better Production",
          entityNumber: entityNumber,
          description:
            "Ensure sustainable consumption and production patterns, through efficient and inclusive food and agriculture supply chains at local, regional and global level, ensuring resilient and sustainable agri-food systems in a changing climate and environment",
          ppaList: ppaInfoList,
        };
    }
  }

  return (
    <PageLayout>
      <Header
        projectName={data?.projectName}
        projectTitle={data?.projectTitle}
        projectSymbol={data?.projectSymbol}
      >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4}>
              <MDBox mb={1.5}>
                <CountContent
                  icon={districtImg}
                  title="Total Budget"
                  count={data?.totalBudgetFPMIS ? USDollar.format(`${data?.totalBudgetFPMIS}`) : 0}
                  loading={loading}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <MDBox mb={1.5}>
                <CountContent
                  icon={districtImg}
                  title="Total Delivery"
                  count={data?.totalDelivery ? USDollar.format(`${data?.totalDelivery}`) : 0}
                  loading={loading}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={4}>
              <MDBox mb={1.5}>
                <CountContent
                  icon={districtImg}
                  title="Funding Source"
                  count={data?.donorAndFundingSource ? data?.donorAndFundingSource : 0}
                  loading={loading}
                  linkTo={`/donor-profile/${encodeURIComponent(data?.donorAndFundingSource)}`}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <Grid container spacing={1}>
          <Grid item xs={12} md={4}>
            {!loading && <ProfileInfoImgCard info={betterInfo} />}
          </Grid>
          <Grid item xs={12} md={4}>
            <ProfileInfoCard title="Description" description={data?.projectObjectives} />
            {/* <MDButton component={Link} to={`/proj-imgs/${id}`} color="dark">
            View Project Highlights
          </MDButton> */}
          </Grid>
          <Grid item xs={12} md={4}>
            <ProfileInfoCard
              info={{
                "Budget Holder": `${data?.projectStaffBudgetHolder}`,
                "LTO Officer": `${data?.projectStaffLTOOfficer}`,
                "Funding Officers": `${data?.projectStaffFundingOfficer}`,
              }}
            />
          </Grid>

          <Grid mt={5}>
            <Card>
              <Grid container justify="center" alignItems="center">
                {loading ? (
                  <CircularProgress />
                ) : (
                  youtubeData?.map((item, index) => {
                    if (item.link.includes("youtube.com")) {
                      item.link = item.link.replace("watch?v=", "embed/");

                      if (item.link.includes("&index=")) {
                        item.link = item.link.split("&index=")[0];
                      }

                      if (item.link.includes("&list=")) {
                        item.link = item.link.split("&list=")[0];
                      }

                      if (item.link.includes("&t=")) {
                        item.link = item.link.split("&t=")[0];
                      }

                      if (item.link.includes("&feature=")) {
                        item.link = item.link.split("&feature=")[0];
                      }

                      if (item.link.includes("&ab_channel=")) {
                        item.link = item.link.split("&ab_channel=")[0];
                      }
                    }

                    if (item.link.includes("youtu.be")) {
                      item.link = item.link.replace("youtu.be", "www.youtube.com/embed");
                    }

                    //const linkCleaned = item.link.substring(item.link.indexOf("http"));
                    if (item.link.indexOf("http") > 0) {
                      item.link = item.link.substring(item.link.indexOf("http"));
                    }

                    return (
                      <Grid item xs={12} md={4} key={index}>
                        <iframe
                          style={{ borderRadius: "10px" }}
                          height={210}
                          width={400}
                          title={item.title}
                          src={item.link}
                          allow="autoplay"
                        />
                      </Grid>
                    );
                  })
                )}
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} xl={6} sx={{ display: "flex" }}>
            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                {!loading ? (
                  socialMediaData?.map((item, index) => {
                    <Link href={item.link} target="_blank" rel="noreferrer">
                      <MDButton color="info">Media Link</MDButton>
                    </Link>;
                  })
                ) : (
                  <CircularProgress />
                )}

                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={12}>
            {!loading && (
              <ProfilesList
                title="Beneficiaries per District per intervention"
                profiles={data?.districtsAndActivity}
                shadow={false}
              />
            )}
          </Grid>

          <Grid item xs={12} xl={12}>
            <GanttChart id={id} />
          </Grid>
        </Grid>
      </Header>
      <FAB actions={navActions()} />
    </PageLayout>
  );
}

ProjectProfile.propTypes = {
  props: PropTypes.string,
};

export default ProjectProfile;
