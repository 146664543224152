/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CircularProgress } from "@mui/material";

function StaticContent({ color, title, count, loading, icon, fromYear, toYear, percentage }) {
  let nf = new Intl.NumberFormat("en-US");

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="lg"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="3rem"
          height="3rem"
          mt={-3}
        >
          <Icon fontSize="large" color="white">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.5}>
          <MDTypography
            variant="h5"
            fontWeight="light"
            color="text"
            style={{ textTransform: "uppercase", letterSpacing: "2px" }}
          >
            {title}
          </MDTypography>
          {loading ? (
            <CircularProgress color={color} />
          ) : (
            <MDTypography variant="h5">{count ? nf.format(count) : "0"}</MDTypography>
          )}
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={2} px={2}>
        <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography component="span" variant="button" fontWeight="bold">
            Values From &nbsp;{fromYear} &nbsp;to &nbsp;{toYear}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of FaoComplexStaticCard
StaticContent.defaultProps = {
  color: "info",
  loading: false,
};

// Typechecking props for the FaoComplexStaticCard
StaticContent.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  loading: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
  fromYear: PropTypes.string.isRequired,
  toYear: PropTypes.string.isRequired,
  percentage: PropTypes.bool,
};

export default StaticContent;
