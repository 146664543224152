import MDTypography from "components/MDTypography";

export default function data(countryData) {
  function limit(string = "", limit = 0) {
    if (string.length < limit) return string;

    return string.substring(0, limit - 4) + "...";
  }

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {});

  const rowData = countryData.map((rows) => ({
    Row_Labels: rows[0],
    Total_Number_Of_Beneficiaries_Reached: rows[1],
  }));

  console.log({ countryData });

  return {
    columns: [
      { Header: "District", accessor: "row", width: "150px", align: "left" },
      {
        Header: "Total Number Of Beneficiaries Reached",
        accessor: "totalBeneficiaries",
        align: "right",
      },
    ],

    rows:
      rowData &&
      rowData.map((row) => {
        return {
          row: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.Row_Labels}
            </MDTypography>
          ),
          totalBeneficiaries: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {USDollar.format(row.Total_Number_Of_Beneficiaries_Reached)}
            </MDTypography>
          ),
        };
      }),
  };
}
