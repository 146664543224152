import axios from "axios";

// const BASE_URL = "http://localhost:8080/api/";
// const BASE_URL = "http://35.154.30.6:8080/api/";
const BASE_URL = "http://faodss.org:8081/api/";

//--FOA DATA MANAGEMENT SYSTEMS URLS--
// export const FOA_BASE_URL = "http://faolkoperations.com:8080/";
export const FOA_BASE_URL = "http://13.126.153.221:8080/";

export default axios.create({ baseURL: BASE_URL });

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-type": "application/json" },
  withCredentials: true,
});
