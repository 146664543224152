/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardNavbar from "examples/Navbars/ResultNavbar";

// Data
import reportsLineChartData from "layouts/dashboard/data/FaoReportsLineChartData";

// Dashboard components
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import { Link } from "react-router-dom";

import { ReactComponent as BackGround1 } from "../../assets/images/fwsvg1.svg";
import { ReactComponent as BackGround2 } from "../../assets/images/fwsvg2.svg";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import FAB from "layouts/FAB";
import { navActions } from "components/FaoNavigation/FaoNavigation";

function ResultDashboard() {
  const { sales, tasks } = reportsLineChartData;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mapData, setMapData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [countryDataList, setCountryDataList] = useState([]);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const fetchEmergency = async () => {
      const controller = new AbortController();
      try {
        setLoading(true);
        const response = await axiosPrivate.get("project-dashboard/emergency", {
          headers: {
            signal: controller.signal,
          },
        });

        setData(response.data);
        let emgData = response.data.countryDistribution;

        const proj_response = await axiosPrivate.get("project-dashboard/emergency/projects", {
          headers: {
            signal: controller.signal,
          },
        });

        let countryData = [];
        let projectData = proj_response.data;

        emgData.map((element) => {
          let country = {
            donorName: element.donorName,
            totalBudget: element.totalBudget,
            totalDelivery: element.totalDelivery,
            project: element.projectList[0],
            // status:
            //   projectData[projectData.findindex((x) => x.projectCode === element.projectList[0])]
            //     .deliveryStatus,
          };

          countryData.push(country);
        });

        setCountryDataList(countryData);

        setProjects(proj_response.data);
        setDistrictData(createDistrictData(response.data.districtDistribution));

        setMapData(getMapData(response.data.budgetMap));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchEmergency();
  }, []);

  const createDistrictData = (data) => {
    // Extracting years and values
    const districts = Object.keys(data);

    const filledData = [];
    districts &&
      districts.forEach((district) => {
        filledData.push(data[district]);
      });

    const budgets = {
      labels: districts,
      datasets: { label: "District", data: filledData },
    };
    return budgets;
  };

  const getMapData = (data) => {
    // Extracting years and values
    const years = Object.keys(data);
    const values = Object.values(data);

    // Finding the earliest and latest years
    const earliestYear = Math.min(...years);
    const latestYear = Math.max(...years);

    // Filling in missing years with zeros
    const filledData = [];
    for (let year = earliestYear; year <= latestYear; year++) {
      // const dataSet = { year: year.toString(), budgetVal: data[year.toString()] };
      const value = data[year.toString()] || 0;
      filledData.push(value);

      // const dataSet = { year: year.toString(), budgetVal: data[year.toString()] };
      // filledData.push(dataSet);
    }

    // Creating the desired format for the line chart
    const budgets = {
      labels: Array.from({ length: latestYear - earliestYear + 1 }, (_, i) =>
        (earliestYear + i).toString()
      ),
      datasets: { label: "Year", data: filledData },
    };
    return budgets;
  };

  function formatNumber(num, precision = 2) {
    const map = [
      { suffix: "T", threshold: 1e12 },
      { suffix: "B", threshold: 1e9 },
      { suffix: "M", threshold: 1e6 },
      { suffix: "K", threshold: 1e3 },
      { suffix: "", threshold: 1 },
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
      return formatted;
    }

    return num;
  }

  const chartData = [
    {
      name: "BE1",
      budget: 0,
    },
    {
      name: "BE2",
      budget: 1783259.0,
    },
    {
      name: "BE3",
      budget: 0,
    },
    {
      name: "BE4",
      budget: 55707.0,
    },
  ];

  const betterLifeData = [
    {
      name: "BL1",
      budget: 1658564.0,
    },
    {
      name: "BL2",
      budget: 91000.0,
    },
    {
      name: "BL3",
      budget: 57534743.8,
    },
    {
      name: "BL4",
      budget: 242174.1,
    },
  ];

  const betterNutritionData = [
    {
      name: "BN1",
      budget: 0,
    },
    {
      name: "BN2",
      budget: 455855.1,
    },
    {
      name: "BN3",
      budget: 1852509.0,
    },
    {
      name: "BN4",
      budget: 0,
    },
  ];

  const betterProductionData = [
    {
      name: "BP1",
      budget: 2049866.0,
    },
    {
      name: "BP2",
      budget: 350000.0,
    },
    {
      name: "BP3",
      budget: 534049.0,
    },
    {
      name: "BP4",
      budget: 200000.0,
    },
  ];

  function handle_onclick(framework) {
    let frameworkval = "";
    switch (framework) {
      case "BE":
        frameworkval = "BE";
        break;
      case "BL":
        frameworkval = "BL";
        break;
      case "BN":
        frameworkval = "BN";
        break;
      case "BP":
        frameworkval = "BP";
        break;
    }

    <Link to={`/four-better-overview/${frameworkval}`} />;
  }

  return (
    <PageLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Link to="/four-better-overview/BE">
              <ResponsiveContainer width="90%" height="100%">
                <ComposedChart
                  layout="vertical"
                  width={500}
                  height={300}
                  data={chartData}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis type="number" fontSize="12px" />
                  <YAxis dataKey="name" fontSize="12px" type="category" scale="band" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="budget" barSize={40} fill="#87BE42" />
                </ComposedChart>
              </ResponsiveContainer>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <BackGround1 />
            {/* <image src={Img2} /> */}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Link to="/four-better-overview/BL">
              <ResponsiveContainer width="90%" height="100%">
                <ComposedChart
                  layout="vertical"
                  width={500}
                  height={300}
                  data={betterLifeData}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis type="number" fontSize="12px" />
                  <YAxis dataKey="name" fontSize="12px" type="category" scale="band" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="budget" barSize={40} fill="#D8363B" />
                </ComposedChart>
              </ResponsiveContainer>
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Link to="/four-better-overview/BP">
              <ResponsiveContainer width="90%" height="100%">
                <ComposedChart
                  layout="vertical"
                  width={500}
                  height={300}
                  data={betterProductionData}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis type="number" fontSize="12px" />
                  <YAxis dataKey="name" fontSize="12px" type="category" scale="band" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="budget" barSize={40} fill="#13A6AA" />
                </ComposedChart>
              </ResponsiveContainer>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <BackGround2 />
            {/* <image src={Img2} /> */}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Link to="/four-better-overview/BN">
              <ResponsiveContainer width="90%" height="100%">
                <ComposedChart
                  layout="vertical"
                  width={500}
                  height={300}
                  data={betterNutritionData}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis type="number" fontSize="12px" />
                  <YAxis dataKey="name" fontSize="12px" type="category" scale="band" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="budget" barSize={40} fill="#F18825" />
                </ComposedChart>
              </ResponsiveContainer>
            </Link>
          </Grid>
        </Grid>
      </MDBox>
      <FAB actions={navActions()} />
    </PageLayout>
  );
}

export default ResultDashboard;
