/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import PropTypes, { any } from "prop-types";

// Data
import FaoDataTable from "./FaoDataTable";
import MDButton from "components/MDButton";
import datatwo from "./datatwo";
import datafour from "./datafour";

function NewNewFaoFinanceList({
  countryData,
  showDelivery = true,
  onDonorRowClick = () => {},
  clickedRowIndex = null,
  clearFilterData = () => {},
}) {
  const { columns, rows } = datafour(countryData);
  const [menu, setMenu] = useState(null);

  const columnData = [
    {
      Header: "",
      accessor: "author",
      align: "left",
      width: "150px",
    },
  ];

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  return (
    <Card style={{ height: "auto", overflow: "auto" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <MDTypography variant="h6" gutterBottom>
          Project List
        </MDTypography>
        {/* <MDButton variant="outlined" color="info" onClick={() => clearFilterData()}>
          <MDTypography variant="button" fontWeight="medium">
            Reset
          </MDTypography>
        </MDButton> */}
      </MDBox>
      <MDBox>
        <FaoDataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
          onRowClick={onDonorRowClick}
          clickedRowIndex={clickedRowIndex}
        />
      </MDBox>
    </Card>
  );
}

NewNewFaoFinanceList.propTypes = {
  countryData: PropTypes.any,
  showDelivery: PropTypes.bool,
  onDonorRowClick: any,
  clickedRowIndex: any,
  clearFilterData: any,
};

export default NewNewFaoFinanceList;
