/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import { Avatar } from "@mui/material";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import MDProgress from "components/MDProgress";

export default function data(projects) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Avatar alt={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>

        <Tooltip title={email} placement="right-start">
          <MDTypography variant="text">{limit(email, 80)}</MDTypography>
        </Tooltip>
      </MDBox>
    </MDBox>
  );

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value.toFixed(2)}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  function limit(string = "", limit = 0) {
    if (string.length < limit) return string;

    return string.substring(0, limit - 4) + "...";
  }

  function getDeliveryStatus(status) {
    switch (status) {
      case "OC":
        return "Op Closed";
      case "FC":
        return "Fi Closed";
      case "AC":
        return "Ac Completed";
      default:
        return "Op Active";
    }
  }

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return {
    columns: [
      { Header: "Code", accessor: "projectCode", width: "45%", align: "left" },
      { Header: "Percentage", accessor: "percentage", align: "right" },
      { Header: "Budget", accessor: "budget", align: "right" },

      { Header: "Expenditure", accessor: "expenditure", align: "right" },
      { Header: "Delivery %", accessor: "deliveryPercentage", align: "right" },
      // { Header: "Status", accessor: "status", align: "left" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],

    rows:
      projects &&
      projects?.map((row) => {
        return {
          projectCode: (
            <Author
              image={<Avatar variant="square" alt={row.projectCode} />}
              name={row.projectCode}
              email={row.projectName}
            />
          ),
          percentage: (
            <>
              <Progress color="success" value={(row.totalActualExpenditure / row.budget) * 100} />,
            </>
          ),

          budget: (
            <>
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {USDollar.format(row.budget)}
              </MDTypography>
            </>
          ),

          expenditure: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {USDollar.format(row.totalActualExpenditure)}
            </MDTypography>
          ),
          deliveryPercentage: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {USDollar.format((row.totalActualExpenditure / row.budget) * 100)}%
            </MDTypography>
          ),
          // status: (
          //   <MDTypography variant="caption" color="text" fontWeight="medium">
          //     {getDeliveryStatus(row.deliveryStatus)}
          //   </MDTypography>
          // ),
          actions: (
            <>
              <MDButton
                component={Link}
                to={`/project-profile/${encodeURIComponent(row.entityNumber)}`}
                color="dark"
              >
                Details
              </MDButton>
              {/* &nbsp;
              <MDButton
                component={Link}
                to={`/result/${encodeURIComponent(row.entityNumber)}/type/CF`}
                color="dark"
              >
                Results
              </MDButton> */}
            </>
          ),
        };
      }),
  };
}
