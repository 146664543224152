/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Images
import agriportal from "assets/images/cropix_img.png";
import esgfb from "assets/images/esgfb.jpg";
import esgp from "assets/images/esgp.jpg";
import esgweb from "assets/images/esgweb.jpg";
import harti from "assets/images/harti.jpg";
import smartAi from "assets/images/smartai.jpg";
import farmer from "assets/images/farmer.jpg";
import nenawagawa from "assets/images/ipnm.jpg";
import seed from "assets/images/seed.jpg";

export default function data() {
  return [
    {
      topic: "SEED Hub",
      description:
        "In Sri Lanka, national agricultural policies prioritize resilience to climate change and reducing production risks. The FAO, with Sri Lanka's collaboration, is developing the “Smart Extension and Efficient Decision-making (S.E.E.D) Hub” e-extension platform. It provides farmers with timely information on production, weather forecasts, and market prices via mobile apps. A pilot phase involves distributing information to farmer organizations in 10 districts, with a control group for comparison. Impact evaluation surveys will gauge its effectiveness in enhancing efficiency and livelihoods. The platform covers climate and crop management, market prices, and advisory services. It aims to decrease the agricultural sector's vulnerability to weather shocks and guide strategic decisions for potential nationwide scaling. Currently, S.E.E.D. Hub focuses on climate and crop management, market prices, and advisory services, delivering valuable insights to farmers for informed decision-making.",
      link: "https://digital.apps.fao.org/home",
      image: seed,
    },
    {
      topic: "Nena Wagawa – LMS for paddy farmers",
      description:
        "Nena Wagawa, also known as Smart Cropping, introduces an innovative approach to agricultural learning in Sri Lanka through its interactive digital platform. This platform is a pioneering space for e-learning, focusing specifically on agriculture and related fields, catering primarily to the needs of paddy farmers. It proudly hosts Sri Lanka's first e-learning course on Integrated Plant Nutrient Management for Paddy Cultivation. This initiative marks a significant step forward in agricultural education, offering a range of self-paced courses that are accessible at any time and from any device. The platform's flexibility and accessibility make it an invaluable resource for farmers seeking to enhance their knowledge and skills in paddy cultivation and beyond.",
      link: "https://nenawagawa.doa.gov.lk/",
      image: nenawagawa,
    },
    {
      topic: "ESG FB page",
      description:
        "The ESG Facebook page serves as a vibrant hub for all the involved schools, enabling them to share updates on their agriculture-related activities. This platform not only acts as a catalytic tool, fostering collaboration and exchange of ideas among schools, but also offers a valuable space for each school to showcase their achievements and the impactful work they are doing in the field of agriculture. Through regular posts and updates, the page facilitates a continuous sharing of knowledge and best practices, enhancing the visibility of the schools' efforts and encouraging a sense of community and shared purpose among them.",
      link: "https://m.facebook.com/groups/3464057980494837/",
      image: esgfb,
    },
    {
      topic: "Entrepreneurial School Garden Programme, website",
      description:
        "The Entrepreneurial School Garden Programme's website serves as a critical platform for spreading knowledge and highlighting exemplary practices within the realm of educational gardening initiatives. It's designed to be a resource hub for educators, students, and gardening enthusiasts who are looking to integrate agricultural concepts into learning environments. Through this website, visitors can access a wealth of information, ranging from instructional guides, success stories, to innovative gardening techniques tailored for educational settings. The aim is to foster a community of learning that supports sustainable practices and cultivates an entrepreneurial spirit among students, by demonstrating the practical applications of gardening in educational contexts.",
      link: "https://schoolgardening.lk/",
      image: esgweb,
    },
    {
      topic: "Entrepreneurial School Garden Programme, web app for school assessment",
      description:
        "The Entrepreneurial School Garden Programme introduces a web application designed specifically for schools to track and report their progress in garden-related activities. This innovative tool allows educational institutions to input their progress data seamlessly, enabling a comprehensive assessment of their efforts. Schools are evaluated on a scale from grade 1 to grade 4 based on the data they submit. The performance grading system plays a crucial role in determining the amount of cash grants awarded to schools. These financial incentives are allocated in accordance with the schools' performance levels, aiming to encourage and reward their commitment to the programme's objectives. This digital approach not only streamlines the process of tracking and evaluating school garden projects but also fosters a competitive spirit among schools, driving them to achieve higher standards of environmental education and sustainability.",
      link: "http://3.6.216.178/",
      image: esgp,
    },
    {
      topic: "Entrepreneurial School Garden Programme, mobile application",
      description:
        "The Entrepreneurial School Garden Programme has integrated technology through a mobile application designed to enhance the visibility and tracking of the school garden's progress. This app functions as a comprehensive dashboard, providing users with real-time updates on the grading status of the school garden. Additionally, it offers a summary of the school's progress, encapsulating various metrics and developments in an easily accessible manner. This digital tool enables educators, students, and stakeholders to stay informed about the garden's achievements and areas for improvement, fostering a more engaged and informed school community.",
      link: "",
      image: esgp,
    },
    {
      topic: "Digital Agriculture Portal (CROPIX)",
      description:
        "This system encompasses a comprehensive suite of components including soil and water test data, which provides crucial insights into the quality and suitability of the land and water for agricultural activities. It also includes data on crop damage caused by pests, diseases, and climate-related factors. Further, the system integrates GAP certification and related information, ensuring adherence to Good Agricultural Practices. Farmer profiles and their lands are meticulously documented, with a seamless linkage to GeoGoviya through APIs, facilitating efficient land management and agricultural planning. Extension officer profiles are included to support and enhance the advisory services provided to farmers. Additionally, the system contains detailed information on crops and crop varieties, along with traceability information for GAP and certified seeds, which is essential for maintaining the quality and safety of agricultural products. Cultivation data is meticulously recorded, providing valuable insights into farming practices and outcomes. Moreover, the system fosters communication and collaboration among farmers and officers, creating a supportive community for sharing knowledge, experiences, and best practices in agriculture.",
      link: "https://digital.doa.gov.lk/",
      image: agriportal,
    },
    {
      topic: "SmartAI – Mobile application for extension officers",
      description:
        "SmartAI is a groundbreaking mobile application designed specifically for extension officers to streamline their operations and enhance their interactions with farmers. This application is set to revolutionize the way extension officers manage their duties by facilitating bi-weekly production reports, which ensures that data on agricultural outputs are consistently updated and monitored. Additionally, SmartAI plays a crucial role in maintaining direct communication with farmers, enabling a seamless exchange of information and advice that is crucial for the success of farming activities. The app also supports Good Agricultural Practices (GAP) and seed certification processes, ensuring that farmers adhere to the best farming practices and that the seeds they use are certified, thereby promoting sustainable and efficient farming. Furthermore, SmartAI is capable of sending extension and advisory messages directly to farmers, providing them with timely and relevant information to aid their farming decisions. Acting as a digital assistant tool for extension officers, SmartAI simplifies their workload and enhances their efficiency, ultimately contributing to improved agricultural practices and productivity.",
      link: "",
      image: smartAi,
    },
    {
      topic: "Smart Farmer mobile application",
      description:
        "The Smart Farmer mobile application serves as an invaluable digital companion for modern agriculture, acting as a smart guru for farmers seeking advice on cultivation practices. Through this app, farmers receive crucial location-based weather updates that enable them to make informed decisions about managing their farms and crop cultivation efficiently. One of the standout features is the ability to report damage directly through the app, streamlining the process for addressing issues as they arise. Furthermore, the app provides access to extension services, enhancing the support network available to farmers. Integrated with various services and institutions, the Smart Farmer app puts a wealth of information at the farmer's fingertips. From crop insurance details and market prices to weather forecasts and other vital data, everything a farmer needs to know is readily accessible, simplifying the complexities of modern farming and empowering farmers to maximize their yield and efficiency.",
      link: "",
      image: farmer,
    },

    {
      topic: "HARTI price data collection application",
      description:
        "The HARTI price data collection application is an innovative tool designed to streamline the process of gathering commodity prices from various economic centers. Integrated with a backend web application, it empowers field officers to input commodity prices directly from the field on a daily basis. This seamless integration significantly enhances the speed and efficiency of data compilation, allowing for real-time updates and accurate tracking of market trends. The mobile application serves as a critical component in ensuring that the data collection process is both fast and reliable, facilitating better decision-making and analysis in the agricultural and economic sectors.",
      link: "",
      image: harti,
    },
    {
      topic: "HARTI consumer application",
      description:
        "The HARTI consumer application stands as a publicly accessible tool designed to empower anyone interested in obtaining daily price data. With the introduction of this application, the barrier between timely market information and the general public has been significantly reduced. Now, price data is instantly available, allowing individuals to access up-to-date information with ease. This advancement not only promotes transparency in market prices but also enables consumers to make more informed decisions based on the latest economic data. The application serves as a bridge, connecting the general public directly to crucial market insights with just a few clicks.",
      link: "",
      image: harti,
    },
  ];
}
