import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { CircularProgress } from "@mui/material";

export function HrPieChart() {
  const options = {
    title: "Gender Distribution",
    fontSize: 17,
  };

  const [loading, setLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const controller = new AbortController();
    const fetchHrGenderData = async () => {
      try {
        setLoading(true);
        const hrUrl = `nshr-listing/gender-distribution`;
        const response = await axiosPrivate.get(hrUrl, {
          headers: { signal: controller.signal },
        });
        const { Male, Female } = response.data;
        const chartData = [
          ["Gender", "Count"],
          ["Male", Male],
          ["Female", Female],
        ];
        setChartData(chartData);
      } catch (error) {
        console.log("Error fetching HR gender data:", error);
      } finally {
        setLoading(false); // Set loading to false when data fetching is complete
      }
    };

    fetchHrGenderData();

    return () => {
      // Cleanup function to abort fetch if component unmounts
      controller.abort();
    };
  }, []); // eslint-disable-line

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Chart
          chartType="PieChart"
          data={chartData}
          options={options}
          width={"100%"}
          height={"500px"}
        />
      )}
    </>
  );
}
