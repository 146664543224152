/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CardActionArea, CardMedia, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

function CountContent({ icon, title, count, loading, linkTo, disable = "false" }) {
  let nf = new Intl.NumberFormat("en-US");

  return (
    <Card sx={{ backgroundColor: disable === "true" ? "#c9c1c1" : "" }}>
      <CardActionArea>
        <Link to={linkTo}>
          <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
            <CardMedia component="img" height="50" image={icon} alt="Donor Count" />
            <MDBox textAlign="left" lineHeight={2.5} pt={2}>
              <MDTypography
                variant="h5"
                fontWeight="light"
                color="text"
                width="90%"
                style={{ textTransform: "uppercase", letterSpacing: "2px" }}
              >
                {title}
              </MDTypography>
            </MDBox>
            {/* <MDBox textAlign="right" lineHeight={2.5} pt={2}>
              {loading ? (
                <CircularProgress />
              ) : (
                <MDTypography variant="h2">{count ? nf.format(count) : "0"}</MDTypography>
              )}
            </MDBox> */}
          </MDBox>
          <Divider />
        </Link>
      </CardActionArea>
    </Card>
  );
}

// Setting default values for the props of FaoComplexStaticCard
CountContent.defaultProps = {
  color: "info",
  loading: false,
};

// Typechecking props for the FaoComplexStaticCard
CountContent.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  loading: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
  fromYear: PropTypes.string.isRequired,
  toYear: PropTypes.string.isRequired,
  percentage: PropTypes.bool,
  linkTo: PropTypes.string.isRequired,
  disable: PropTypes.string,
};

export default CountContent;
