/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardNavbar from "examples/Navbars/CountryProgramNavbar";

// Data
import reportsLineChartData from "layouts/dashboard/data/FaoReportsLineChartData";

// Dashboard components
import PageLayout from "examples/LayoutContainers/PageLayout";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Box, Card, FormControlLabel, Slider, Switch, Typography } from "@mui/material";
import MDButton from "components/MDButton";

import CountryProjectList from "./components/projectList";

import FAB from "layouts/FAB";
import OZDivider from "components/OZDivider";
import { navActions } from "components/FaoNavigation/FaoNavigation";
import { useLocation, useParams } from "react-router-dom";

function valuetext(value) {
  return `${value}`;
}

function CountryProgramProjList() {
  const { sales, tasks } = reportsLineChartData;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mapData, setMapData] = useState([]);
  const [projects, setProjects] = useState([]);
  console.log({ projects });
  const [districtData, setDistrictData] = useState([]);
  const [countryDataList, setCountryDataList] = useState([]);

  const [checkedOa, setCheckedOa] = useState(false);
  const [checkedAc, setCheckedAc] = useState(false);
  const [checkedFc, setCheckedFc] = useState(false);
  const [checkedPipelined, setCheckedPipelined] = useState(false);
  const [checkedOc, setCheckedOc] = useState(false);

  const [checkedTcp, setCheckedTcp] = useState(false);
  const [checkedEmergency, setCheckedEmergency] = useState(false);
  const [checkedOtherDevProjects, setCheckedOtherDevProjects] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const [value, setValue] = useState([1987, 2024]);

  const [startDate, setStartDate] = useState(1987);
  const [endDate, setEndDate] = useState(2024);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStartDate(newValue[0]);
    setEndDate(newValue[1]);
    filterData(newValue[0], newValue[1]);
  };

  useEffect(() => {
    const statusFilter = localStorage.getItem("statusFilter");
    const typeFilter = localStorage.getItem("typeFilter");

    if (statusFilter) {
      if (statusFilter.includes("OA")) {
        setCheckedOa(true);
      }
      if (statusFilter.includes("AC")) {
        setCheckedAc(true);
      }
      if (statusFilter.includes("FC")) {
        setCheckedFc(true);
      }
      if (statusFilter.includes("P1,P2,P3")) {
        setCheckedPipelined(true);
      }
      if (statusFilter.includes("OC")) {
        setCheckedOc(true);
      }
    }

    if (typeFilter) {
      if (typeFilter.includes("FAO")) {
        setCheckedTcp(true);
      }
      if (typeFilter.includes("EMERGENCY")) {
        setCheckedEmergency(true);
      }
      if (typeFilter.includes("OTHER")) {
        setCheckedOtherDevProjects(true);
      }
    }

    //filterData(null, null);
  }, []);

  const createDistrictData = (data) => {
    // Extracting years and values
    const districts = Object.keys(data);

    const filledData = [];
    districts.forEach((district) => {
      filledData.push(data[district]);
    });

    const budgets = {
      labels: districts,
      datasets: { label: "District", data: filledData },
    };
    return budgets;
  };

  const getMapData = (data) => {
    // Extracting years and values
    const years = Object.keys(data);
    const values = Object.values(data);

    // Finding the earliest and latest years
    const earliestYear = Math.min(...years);
    const latestYear = Math.max(...years);

    // Filling in missing years with zeros
    const filledData = [];
    for (let year = earliestYear; year <= latestYear; year++) {
      // const dataSet = { year: year.toString(), budgetVal: data[year.toString()] };
      const value = data[year.toString()] || 0;
      filledData.push(value);

      // const dataSet = { year: year.toString(), budgetVal: data[year.toString()] };
      // filledData.push(dataSet);
    }

    // Creating the desired format for the line chart
    const budgets = {
      labels: Array.from({ length: latestYear - earliestYear + 1 }, (_, i) =>
        (earliestYear + i).toString()
      ),
      datasets: { label: "Year", data: filledData },
    };
    return budgets;
  };

  function formatNumber(num, precision = 2) {
    const map = [
      { suffix: "T", threshold: 1e12 },
      { suffix: "B", threshold: 1e9 },
      { suffix: "M", threshold: 1e6 },
      { suffix: "K", threshold: 1e3 },
      { suffix: "", threshold: 1 },
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
      return formatted;
    }

    return num;
  }

  useEffect(() => {
    let filter = "";
    if (checkedOa) {
      filter += "OA,";
    }
    if (checkedAc) {
      filter += "AC,";
    }
    if (checkedFc) {
      filter += "FC,";
    }
    if (checkedPipelined) {
      filter += "P1,P2,P3,";
    }
    if (checkedOc) {
      filter += "OC,";
    }
    filter = filter.endsWith(",") ? filter.slice(0, -1) : filter;
    //setStatusFilter(filter);
    localStorage.setItem("statusFilter", filter);

    let typeFilter = "";
    if (checkedTcp) {
      typeFilter += "FAO,";
    }
    if (checkedEmergency) {
      typeFilter += "EMERGENCY,";
    }
    if (checkedOtherDevProjects) {
      typeFilter += "OTHER,";
    }

    typeFilter = typeFilter.endsWith(",") ? typeFilter.slice(0, -1) : typeFilter;
    //setTypeFilter(typeFilter);
    localStorage.setItem("typeFilter", typeFilter);
  }, [
    checkedOa,
    checkedAc,
    checkedFc,
    checkedPipelined,
    checkedTcp,
    checkedEmergency,
    checkedOtherDevProjects,
  ]);

  const ClearFilterData = () => {
    localStorage.setItem("statusFilter", "");
    localStorage.setItem("typeFilter", "");

    setCheckedOa(false);

    setCheckedAc(false);

    setCheckedFc(false);

    setCheckedPipelined(false);

    setCheckedOc(false);

    setCheckedTcp(false);

    setCheckedEmergency(false);

    setCheckedOtherDevProjects(false);

    setStartDate(1987);
    setEndDate(2024);

    setValue([1987, 2024]);

    filterData(null, null);
  };

  const onfilterData = () => {
    setStartDate(1987);
    setEndDate(2024);
    setValue([1987, 2024]);
    filterData(null, null);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const GLO = searchParams.get("GLO");
  const RAS = searchParams.get("RAS");
  const PIPE = searchParams.get("PIPE");
  const OA = searchParams.get("OA");
  console.log({ GLO });
  console.log({ RAS });

  useEffect(() => {
    let url = "project-dashboard/projects?";
    const controller = new AbortController();
    if (GLO === "GLO") {
      setCheckedPipelined(false);
      url = "/country-program-frameworks/project-list?projectType=GLO,INT";
    } else if (RAS === "RAS") {
      setCheckedPipelined(false);
      url = "/country-program-frameworks/project-list?projectType=RAS";
    } else if (PIPE === "PIPE") {
      setCheckedPipelined(true);
      url = "project-dashboard/projects?projectStatusCodes=P1,P2,P3";
    } else if (OA === "OA") {
      setCheckedPipelined(false);
      setCheckedOa(true);
      url = "project-dashboard/projects?projectStatusCodes=OA";
    } else {
      setCheckedPipelined(false);
    }

    const newFilterData = async () => {
      setLoading(true);
      const response = await axiosPrivate.get(url, {
        headers: {
          signal: controller.signal,
        },
      });

      setProjects(response?.data);
      setLoading(false);
    };

    newFilterData();
  }, [GLO, RAS, PIPE]);

  // useEffect(() => {
  //   setParams(para);
  // }, [para]);
  const filterData = async (sDate, eDate) => {
    const controller = new AbortController();
    try {
      setLoading(true);
      const statusFilter = localStorage.getItem("statusFilter");
      const typeFilter = localStorage.getItem("typeFilter");

      let url = "project-dashboard?";
      let projUrl = "project-dashboard/projects?";
      if (statusFilter) {
        url += "projectStatusCodes=" + statusFilter;
        projUrl += "projectStatusCodes=" + statusFilter;
      }
      if (typeFilter) {
        if (statusFilter) {
          url += "&";
          projUrl += "&";
        }
        url += "projectType=" + typeFilter;
        projUrl += "projectType=" + typeFilter;
      }

      if (sDate && eDate) {
        if (typeFilter || statusFilter) {
          url += "&";
          projUrl += "&";
        }
        url += "startDate=" + sDate + "&endDate=" + eDate;
        projUrl += "startDate=" + sDate + "&endDate=" + eDate;
      }

      const response = await axiosPrivate.get(url, {
        headers: {
          signal: controller.signal,
        },
      });

      setData(response.data);
      let emgData = response.data.countryDistribution;

      const proj_response = await axiosPrivate.get(projUrl, {
        headers: {
          signal: controller.signal,
        },
      });

      let countryData = [];
      let projectData = proj_response.data;
      emgData.map((element) => {
        let country = {
          donorName: element.donorName,
          totalBudget: element.totalBudget,
          totalDelivery: element.totalDelivery,
          project: element.projectList[0],
        };

        countryData.push(country);
      });

      setCountryDataList(countryData);

      setProjects(proj_response.data);
      setDistrictData(createDistrictData(response.data.districtDistribution));

      setMapData(getMapData(response.data.budgetMap));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  function handleBack() {
    window.history.back();
  }

  return (
    <PageLayout>
      <DashboardNavbar />
      <Card>
        <MDBox py={1} px={2} display="flex" justifyContent="flex-end">
          <MDBox display="flex" alignItems="flex-start" lineHeight={0}>
            {/* <FormControlLabel
              control={
                <Switch
                  checked={checkedTcp}
                  onChange={(event) => setCheckedTcp(event.target.checked)}
                  value="FAO"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="FAO"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={checkedEmergency}
                  onChange={(event) => setCheckedEmergency(event.target.checked)}
                  value="EMERGENCY"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Emergency"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={checkedOtherDevProjects}
                  onChange={(event) => setCheckedOtherDevProjects(event.target.checked)}
                  value="OtherDevProjects"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Development Projects"
            /> */}
          </MDBox>
          {/* <OZDivider /> */}

          <MDBox ml={3} display="flex" style={{ justifyContent: "flex-end" }} lineHeight={0}>
            <MDButton
              color="dark"
              onClick={() => {
                handleBack();
              }}
            >
              Back
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      <MDBox py={3} mt={1} px={4}>
        {!loading && <CountryProjectList projects={projects} />}
      </MDBox>

      <FAB actions={navActions()} />
    </PageLayout>
  );
}

export default CountryProgramProjList;
