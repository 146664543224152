// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Overview page components
import Header from "./components/Header";

// Images
import be from "assets/images/bettericon/be.svg";
import bl from "assets/images/bettericon/bl.svg";
import bn from "assets/images/bettericon/bn.svg";
import bp from "assets/images/bettericon/bp.svg";

import g1 from "assets/images/sdg/g1.png";
import g2 from "assets/images/sdg/g2.png";
import g3 from "assets/images/sdg/g3.png";
import g5 from "assets/images/sdg/g5.png";
import g6 from "assets/images/sdg/g6.png";
import g8 from "assets/images/sdg/g8.png";
import g9 from "assets/images/sdg/g9.png";
import g10 from "assets/images/sdg/g10.png";
import g11 from "assets/images/sdg/g11.png";
import g12 from "assets/images/sdg/g12.png";
import g13 from "assets/images/sdg/g13.png";
import g14 from "assets/images/sdg/g14.png";
import g15 from "assets/images/sdg/g15.png";
import g16 from "assets/images/sdg/g16.png";
import g17 from "assets/images/sdg/g17.png";

import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { Typography } from "@mui/material";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/CpStatisticCard";
import PpaTable from "./components/PpaTable";

import projData from "./components/data";
import CpfOverview from "./components/CpfOverview";
import CpfSubOutput from "./components/CpfSubOutPut";
import FAB from "layouts/FAB";
import { navActions } from "components/FaoNavigation/FaoNavigation";

function FourBetterOverview() {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [topic, setTopic] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEmergency = async () => {
      const controller = new AbortController();
      try {
        setLoading(true);
        const response = await axiosPrivate.get(`/four-better-mappings/cpf-projects/${id}`, {
          headers: {
            signal: controller.signal,
          },
        });

        switch (id) {
          case "BE":
            setTopic({
              name: "Better Environment",
              icon: be,
              description:
                "Protect, restore and promote sustainable use of terrestrial and marine ecosystems and combat climate change (reduce, reuse, recycle, residual management) through more efficient, inclusive, resilient and sustainable agri-food systems",
              budget: 1838966.0,
            });
            break;
          case "BL":
            setTopic({
              name: "Better Life",
              icon: bl,
              description:
                "Promote inclusive economic growth by reducing inequalities (urban/rural areas, rich/poor countries, men/women)",
              budget: 59526481.9,
            });
            break;
          case "BN":
            setTopic({
              name: "Better Nutrition",
              icon: bn,
              description:
                "End hunger, achieve food security and improved nutrition in all its forms, including promoting nutritious food and increasing access to healthy diets",
              budget: 2308364.1,
            });
            break;
          default:
            setTopic({
              name: "Better Production",
              icon: bp,
              description:
                "Ensure sustainable consumption and production patterns, through efficient and inclusive food and agriculture supply chains at local, regional and global level, ensuring resilient and sustainable agri-food systems in a changing climate and environment",
              budget: 3133915.0,
            });
        }

        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchEmergency();
  }, []);

  function getDescription(fwCode) {
    switch (fwCode) {
      case "BE1":
        return "Climate change mitigating and adapted agri-food systems";
      case "BE2":
        return "Bioeconomy for sustainable food and agriculture";
      case "BE3":
        return "Biodiversity and ecosystem services for food and agriculture";
      case "BE4":
        return "Achieving sustainable urban food systems";
      case "BP1":
        return "Innovation for sustainable agriculture production";
      case "BP2":
        return "Blue transformation";
      case "BP3":
        return "One Health";
      case "BP4":
        return "Small-Scale Producers’ Equitable Access to Resources";
      case "BP5":
        return "Digital Agriculture";
      case "BN1":
        return "Healthy diets for all";
      case "BN2":
        return "Nutrition for the most vulnerable";
      case "BN3":
        return "Safe food for everyone";
      case "BN4":
        return "Reducing food loss and waste";
      case "BN5":
        return "Transparent markets and trade";
      case "BL1":
        return "Gender equality and rural women’s empowerment";
      case "BL2":
        return "Inclusive rural transformation";
      case "BL3":
        return "Agriculture and food emergencies";
      case "BL4":
        return "Resilient agri-food systems";
      case "BL5":
        return "Hand-in-Hand (HIH) Initiative";
      case "BL6":
        return "Scaling up investment";
      default:
        return "Better Production";
    }
  }

  const ppaCombination = {
    BP1: [g2, g6, g9, g15],
    BP2: [g2, g14],
    BP3: [g1, g3, g15],
    BP4: [g1, g2, g6, g9, g14],
    BP5: [g1, g2, g5, g9, g17],
    BN1: [g1, g2, g3, g12],
    BN2: [g1, g2, g3],
    BN3: [g2, g3],
    BN4: [g2, g12],
    BN5: [g2, g10, g17],
    BL1: [g2, g5],
    BL2: [g1, g8, g10],
    BL3: [g1, g2, g16],
    BL4: [g1, g2],
    BL5: [g1, g2, g10],
    BL6: [g1, g2, g10, g17],
    BE1: [g2, g6, g13, g14],
    BE2: [g12],
    BE3: [g2, g6, g14, g15],
    BE4: [g1, g2, g11, g12],
  };

  return (
    <PageLayout>
      {/* <DashboardNavbar /> */}
      <MDBox mb={2} />
      <Header projectName={topic.name} projectTitle={topic.description} media={topic.icon}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} xl={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} xl={12}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="weekend"
                    title="Budget"
                    count={topic.budget}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={12}>
                  <CpfOverview data={data?.cpfOutputs} />
                </Grid>
                <Grid item xs={12} md={6} xl={12}>
                  <CpfSubOutput data={data?.subOutputs} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6} xl={8}>
              {data?.ppaProjects?.map((project) => {
                if (project.projects.length === 0) return null;

                const { columns, rows } = projData(project.projects, project.ppa);
                return (
                  <>
                    <Typography
                      style={{ backgroundColor: "success" }}
                      variant="h5"
                      color="text"
                      fontWeight="bold"
                    >
                      {project.ppa} - {getDescription(project.ppa)}
                    </Typography>
                    {ppaCombination[project.ppa].map((sgd, index) => {
                      return (
                        <img
                          width="50px"
                          style={{ margin: "2px" }}
                          key={index}
                          src={sgd}
                          alt={project.ppa}
                        />
                      );
                    })}

                    <PpaTable
                      table={{ columns, rows }}
                      showTotalEntries={false}
                      isSorted={false}
                      entriesPerPage={false}
                    />
                    <Divider orientation="horizontal" flexItem style={{ margin: "15px 2px" }} />
                  </>
                );
              })}
            </Grid>
          </Grid>
        </MDBox>
        <FAB actions={navActions()} />
      </Header>
    </PageLayout>
  );
}

FourBetterOverview.propTypes = {
  props: PropTypes.string,
};

export default FourBetterOverview;
