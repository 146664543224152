/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

// Images
import Flag from "react-world-flags";

export default function data(countryData) {
  const Author = ({ countryCode, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Flag code={countryCode} height="30" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>

        <Tooltip title={email} placement="right-start">
          <MDTypography variant="text">{limit(email, 100)}</MDTypography>
        </Tooltip>
      </MDBox>
    </MDBox>
  );

  function limit(string = "", limit = 0) {
    if (string.length < limit) return string;

    return string.substring(0, limit - 4) + "...";
  }

  function getDeliveryStatus(status) {
    switch (status) {
      case "OC":
        return "Op Closed";
      case "FC":
        return "Fi Closed";
      case "AC":
        return "Ac Completed";
      case "OA":
        return "Op Active";
    }
  }
  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function getCountryCode(country) {
    switch (country) {
      case "Canada":
        return "can";
      case "United States":
        return "usa";
      case "UK" || "United Kingdom":
        return "gbr";
      case "Germany: BMZ" || "Germany":
        return "ger";
      case "Japan":
        return "jpn";
      case "Australia":
        return "aus";
      case "Netherlands: DGIS" || "Netherlands":
        return "ned";
      case "Norway: NORAD" || "Norway":
        return "nor";
      case "Sweden":
        return "swe";
      case "Switzerland: SDC" || "Switzerland":
        return "swi";
      case "Denmark: DANIDA" || "Denmark":
        return "den";
      case "Italy: AICS" || "Italy":
        return "ita";
      case "New Zealand":
        return "nzl";
      case "European Union":
        return "EU";
      case "China":
        return "chn";
      default:
        "sl";
    }
  }

  const rowData = [
    {
      Row_Labels: "5013 Consultants",
      Sum_of_Soft_Commitment: 0,
      Sum_of_Hard_Commitment: 44865,
      Sum_of_Actuals: 157828,
      Sum_of_Available_Budget: 550,
      Sum_of_Budget: 203243,
      "Sum_of_Commitments_&_Actuals": 202693,
    },
    {
      Row_Labels: "5014 Contracts",
      Sum_of_Soft_Commitment: 0,
      Sum_of_Hard_Commitment: 5362,
      Sum_of_Actuals: 12630,
      Sum_of_Available_Budget: 107440,
      Sum_of_Budget: 125432,
      "Sum_of_Commitments_&_Actuals": 17992,
    },
    {
      Row_Labels: "5020 Locally Contracted Labour",
      Sum_of_Soft_Commitment: 0,
      Sum_of_Hard_Commitment: 0,
      Sum_of_Actuals: 2416,
      Sum_of_Available_Budget: -2416,
      Sum_of_Budget: 0,
      "Sum_of_Commitments_&_Actuals": 2416,
    },
    {
      Row_Labels: "5021 Travel",
      Sum_of_Soft_Commitment: 0,
      Sum_of_Hard_Commitment: 737,
      Sum_of_Actuals: 11348,
      Sum_of_Available_Budget: 6459,
      Sum_of_Budget: 18544,
      "Sum_of_Commitments_&_Actuals": 12085,
    },
    {
      Row_Labels: "5023 Training",
      Sum_of_Soft_Commitment: 0,
      Sum_of_Hard_Commitment: 0,
      Sum_of_Actuals: 0,
      Sum_of_Available_Budget: 0,
      Sum_of_Budget: 0,
      "Sum_of_Commitments_&_Actuals": 0,
    },
    {
      Row_Labels: "5024 Expendable Procurement",
      Sum_of_Soft_Commitment: 26997,
      Sum_of_Hard_Commitment: 0,
      Sum_of_Actuals: 3924067,
      Sum_of_Available_Budget: -141264,
      Sum_of_Budget: 3809800,
      "Sum_of_Commitments_&_Actuals": 3951064,
    },
    {
      Row_Labels: "5025 Non Expendable Procurement",
      Sum_of_Soft_Commitment: 0,
      Sum_of_Hard_Commitment: 0,
      Sum_of_Actuals: 3391,
      Sum_of_Available_Budget: 1153,
      Sum_of_Budget: 4544,
      "Sum_of_Commitments_&_Actuals": 3391,
    },
    {
      Row_Labels: "5027 Technical Support Services",
      Sum_of_Soft_Commitment: 0,
      Sum_of_Hard_Commitment: 0,
      Sum_of_Actuals: 8625,
      Sum_of_Available_Budget: 57880,
      Sum_of_Budget: 66505,
      "Sum_of_Commitments_&_Actuals": 8625,
    },
    {
      Row_Labels: "5028 General Operating Expenses",
      Sum_of_Soft_Commitment: 0,
      Sum_of_Hard_Commitment: 0,
      Sum_of_Actuals: 16804,
      Sum_of_Available_Budget: 19646,
      Sum_of_Budget: 36450,
      "Sum_of_Commitments_&_Actuals": 16804,
    },
    {
      Row_Labels: "5029 Support Costs",
      Sum_of_Soft_Commitment: 0,
      Sum_of_Hard_Commitment: 0,
      Sum_of_Actuals: 292853,
      Sum_of_Available_Budget: 10020,
      Sum_of_Budget: 302873,
      "Sum_of_Commitments_&_Actuals": 292853,
    },
    {
      Row_Labels: "5040 General Operating Expenses - external common services",
      Sum_of_Soft_Commitment: 0,
      Sum_of_Hard_Commitment: 0,
      Sum_of_Actuals: 0,
      Sum_of_Available_Budget: 0,
      Sum_of_Budget: 0,
      "Sum_of_Commitments_&_Actuals": 0,
    },
    {
      Row_Labels: "5050 Internal Common Services and Support",
      Sum_of_Soft_Commitment: 0,
      Sum_of_Hard_Commitment: 0,
      Sum_of_Actuals: 55025,
      Sum_of_Available_Budget: 7213,
      Sum_of_Budget: 62238,
      "Sum_of_Commitments_&_Actuals": 55025,
    },
  ];

  return {
    columns: [
      { Header: "Row Labels", accessor: "row", width: "45%", align: "left" },
      { Header: "Sum of Soft Commitment", accessor: "totalBudget", align: "right" },
      { Header: "Sum of Actuals", accessor: "totalDelivery", align: "right" },
      { Header: "Sum of Available Budget", accessor: "totalDelivery", align: "right" },
      { Header: "Sum of Budget", accessor: "totalDelivery", align: "right" },
      { Header: "Sum of Commitments & Actuals", accessor: "totalDelivery", align: "right" },
    ],

    rows:
      rowData &&
      rowData.map((row) => {
        return {
          row: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {USDollar.format(row.Row_Labels)}
            </MDTypography>
          ),
          totalBudget: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {USDollar.format(row.totalBudget)}
            </MDTypography>
          ),
          totalDelivery: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {USDollar.format(row.totalDelivery)}
            </MDTypography>
          ),
          deliveryPercentage: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {((row.totalDelivery / row.totalBudget) * 100).toFixed(2)}%
            </MDTypography>
          ),
          status: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {getDeliveryStatus(row.status)}
            </MDTypography>
          ),
          actions: (
            <MDButton
              component={Link}
              to={`/donor-profile/${encodeURIComponent(row.entityNumber)}`}
              color="dark"
            >
              Details
            </MDButton>
          ),
        };
      }),
  };
}
