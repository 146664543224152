import slides from "./data/slides";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";
import PageLayout from "examples/LayoutContainers/PageLayout";
import MDBox from "components/MDBox";
import Header from "layouts/ProjectProfile/components/Header";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import Lightbox from "yet-another-react-lightbox";
import FAB from "layouts/FAB";
import { navActions } from "components/FaoNavigation/FaoNavigation";

export default function ProjImgGallery() {
  const [index, setIndex] = useState(-1);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const axiosPrivate = useAxiosPrivate();

  const { id } = useParams();

  useEffect(() => {
    const fetchEmergency = async () => {
      const controller = new AbortController();
      try {
        setLoading(true);
        const response = await axiosPrivate.get(`project-dashboard/emergency/projects/${id}`, {
          headers: {
            signal: controller.signal,
          },
        });
        setData(response.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchEmergency();
  }, []);

  return (
    <PageLayout>
      <MDBox mb={3} />
      <Header
        projectName={data.projectName}
        projectTitle={data.projectTitle}
        projectSymbol={data.projectSymbol}
      >
        <PhotoAlbum
          layout="rows"
          photos={slides}
          targetRowHeight={150}
          onClick={({ index: current }) => setIndex(current)}
        />
        <Lightbox
          plugins={[Thumbnails]}
          index={index}
          slides={slides}
          open={index >= 0}
          close={() => setIndex(-1)}
        />
      </Header>
      <FAB actions={navActions()} />
    </PageLayout>
  );
}

ProjImgGallery.propTypes = {
  data: {
    projectName: "Project Name",
    projectTitle: "Project Title",
    projectSymbol: "Project Symbol",
  },
};
