import React from "react";
import Card from "@mui/material/Card";
import { Avatar, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

function BeginCard({ image, title, linkTo }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(cardTitle) {
    return {
      sx: {
        bgcolor: stringToColor(cardTitle),
      },
      children: `${cardTitle.split(" ")[0][0]}${cardTitle.split(" ")[1][0]}`,
    };
  }

  return (
    <Card>
      <MDButton
        variant="variant"
        component={Link}
        to={linkTo}
        color="none"
        style={{ justifyContent: "flex-start" }}
      >
        <MDBox
          borderRadius="lg"
          p={0.75}
          sx={{
            border: ({ borders: { borderWidth, borderColor } }) =>
              `${borderWidth[0]} solid ${borderColor}`,
          }}
        >
          <Grid container spacing={1}>
            <Grid item lg={2}>
              <Avatar alt={title} src={image} />
            </Grid>
            <Grid
              item
              lg={10}
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
              }}
            >
              <MDTypography variant="h5" fontWeight="medium">
                {title}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </MDButton>
    </Card>
  );
}

BeginCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default BeginCard;
