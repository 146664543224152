import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";

const HrGanttChart = ({ hrArray, loading }) => {
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Chart chartType="Timeline" width="100%" height="400px" data={hrArray} />
      )}
    </>
  );
};

HrGanttChart.defaultProps = {
  hrArray: [],
  loading: false,
};

HrGanttChart.propTypes = {
  hrArray: PropTypes.object,
  loading: PropTypes.bool,
};

export default HrGanttChart;
