/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";

// Images
import Flag from "react-world-flags";

export default function datatwo(countryData) {
  const Author = ({ countryCode, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Flag code={countryCode} height="30" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>

        <Tooltip title={email} placement="right-start">
          <MDTypography variant="text">{limit(email, 100)}</MDTypography>
        </Tooltip>
      </MDBox>
    </MDBox>
  );

  function limit(string = "", limit = 0) {
    if (string.length < limit) return string;

    return string.substring(0, limit - 4) + "...";
  }

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value.toFixed(2)}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  function getDeliveryStatus(status) {
    switch (status) {
      case "OC":
        return "Op Closed";
      case "FC":
        return "Fi Closed";
      case "AC":
        return "Ac Completed";
      case "OA":
        return "Op Active";
    }
  }

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const rowData = countryData.map((rows) => ({
    project: rows.projectSymbol,
    title: rows.projectTitle,
    budget: rows.totalBudget,
    expenditure: rows.totalDelivery,
    delivery: rows.expenditure,
  }));
  console.log("rowData", rowData);
  return {
    columns: [
      { Header: "CODE", accessor: "project", width: "15%", align: "left" },
      // { Header: "TITLE", accessor: "title", align: "left" },
      { Header: "PERCENTAGE", accessor: "percentage", width: "15%", align: "center" },
      { Header: "BUDGET", accessor: "budget", align: "center" },
      { Header: "EXPENDITURE", accessor: "expenditure", align: "center" },
      { Header: "DELIVERY", accessor: "delivery", align: "center" },
    ],

    rows:
      rowData &&
      rowData.map((row) => {
        return {
          project: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.project}
            </MDTypography>
          ),
          title: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.title}
            </MDTypography>
          ),
          percentage: (
            <>
              <Progress color="success" value={(row.expenditure / row.budget) * 100} />,
            </>
          ),
          budget: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.budget ? USDollar.format(row.budget) : "$0.00"}
            </MDTypography>
          ),
          expenditure: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.expenditure ? USDollar.format(row.expenditure) : "$0.00"}
            </MDTypography>
          ),
          delivery: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.delivery ? USDollar.format(row.delivery) : "$0.00"}
            </MDTypography>
          ),
        };
      }),
  };
}
