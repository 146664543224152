/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import MDTypography from "components/MDTypography";

export default function data(countryData) {
  return {
    columns: [
      { Header: "id", accessor: "id", width: "150px", align: "left" },
      { Header: "code", accessor: "code", align: "left" },
      { Header: "name", accessor: "name", align: "left" },
    ],

    rows:
      countryData &&
      countryData.map((row) => {
        return {
          id: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.id}
            </MDTypography>
          ),
          code: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.code}
            </MDTypography>
          ),
          name: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.name}
            </MDTypography>
          ),
        };
      }),
  };
}
