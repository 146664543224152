import { Divider } from "@mui/material";
import React from "react";
import propTypes from "prop-types";

function OZDivider({ orientation }) {
  return (
    <Divider
      orientation={orientation}
      flexItem
      style={{ height: "auto", margin: "0 10px", width: "4px" }}
    />
  );
}

OZDivider.defaultProps = {
  orientation: "horizontal",
};

OZDivider.propTypes = {
  orientation: propTypes.string,
};

export default OZDivider;
