/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { Avatar, Tooltip } from "@mui/material";
import borders from "assets/theme/base/borders";

// Images

export default function data(tabData) {
  let formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
  });
  return {
    columns1: [
      { Header: "District", accessor: "district", width: "15%", align: "left" },
      { Header: "Input Distribution (MT)", accessor: "input", width: "15%", align: "left" },
      { Header: "Cash Distribution ($)", accessor: "cash", width: "15%", align: "left" },
      { Header: "Capacity Building", accessor: "capacity", width: "15%", align: "left" },
      { Header: "Nutrition Assistance", accessor: "nutrician", width: "15%", align: "left" },
      { Header: "OTHER", accessor: "OTHER", width: "15%", align: "left" },
    ],

    rows1: Object.entries(tabData).map(([district, activities]) => ({
      district: (
        <MDTypography
          key={`${district}-district`}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {district}
        </MDTypography>
      ),
      input: (
        <MDTypography
          key={`${district}-input`}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {activities["Input Distribution"]
            ? formatter.format(activities["Input Distribution"])
            : ""}
        </MDTypography>
      ),
      cash: (
        <MDTypography
          key={`${district}-cash`}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {activities["Cash Distribution"] ? formatter.format(activities["Cash Distribution"]) : ""}
        </MDTypography>
      ),
      capacity: (
        <MDTypography
          key={`${district}-capacity`}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {activities["Capacity Building"] ? formatter.format(activities["Capacity Building"]) : ""}
        </MDTypography>
      ),
      nutrician: (
        <MDTypography
          key={`${district}-nutrician`}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {activities["Nutrician Assistance"]
            ? formatter.format(activities["Nutrician Assistance"])
            : ""}
        </MDTypography>
      ),
      OTHER: (
        <MDTypography
          key={`${district}-OTHER`}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {activities["OTHER"] ? formatter.format(activities["OTHER"]) : ""}
        </MDTypography>
      ),
    })),
  };
}
