/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import PropTypes from "prop-types";

function CpfOverview({ data }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Relationship to CPF Results
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        {data.map((item, index) => {
          return (
            <TimelineItem
              key={index}
              color={
                (index + 1) % 3 === 1 ? "success" : (index + 1) % 3 === 2 ? "error" : "warning"
              }
              icon={
                (index + 1) % 3 === 1
                  ? "notifications"
                  : (index + 1) % 3 === 2
                  ? "inventory_2"
                  : "payment"
              }
              title={item.cpdPriority}
              dateTime={item.cpfOutput}
              description={item.unsdcfOutcome}
            />
          );
        })}
        ;
      </MDBox>
    </Card>
  );
}

CpfOverview.defaultProps = {
  data: [],
};

CpfOverview.propTypes = {
  data: PropTypes.array,
};

export default CpfOverview;
