/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import PropTypes from "prop-types";

// Data
import data from "./data";
import FaoDataTable from "examples/Tables/FaoDataTable";

function CountryProjectList({ projects }) {
  const { columns, rows } = data(projects);
  const [menu, setMenu] = useState(null);

  const [fcCount, setFcCount] = useState(0);
  const [ocCount, setOcCount] = useState(0);
  const [oaCount, setOaCount] = useState(0);
  const [accCount, setAccCount] = useState(0);
  const [pCount, setPCount] = useState(0);

  const [checkedAgri, setCheckedAgri] = useState(true);
  const [checkedFisheries, setCheckedFisheries] = useState(true);
  const [checkedLivelihood, setCheckedLivelihood] = useState(true);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(() => {
    projects.map((project) => {
      switch (project.deliveryStatus) {
        case "FC":
          setFcCount(fcCount + 1);
          break;
        case "OC":
          setOcCount(ocCount + 1);
          break;
        case "OA":
          setOaCount(oaCount + 1);
          break;
        case "AC":
          setAccCount(accCount + 1);
          break;
        default:
          setPCount(pCount + 1);
          break;
      }
    });
    return () => {
      setMenu(null);
    };
  }, [projects]);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <MDBox mt={3}>
      <Card style={{ height: "auto", overflow: "auto" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
          <MDTypography variant="h6" gutterBottom>
            Project List
          </MDTypography>
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <Icon
              sx={{
                fontWeight: "bold",
                color: ({ palette: { info } }) => info.main,
                mt: -0.5,
              }}
            >
              done
            </Icon>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;Number of Projects &nbsp;<strong>{projects.length} </strong>
            </MDTypography>
          </MDBox>
        </MDBox>
        {/* <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {renderMenu} */}
        <MDBox>
          <FaoDataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
          />
        </MDBox>
      </Card>
    </MDBox>
  );
}

CountryProjectList.propTypes = {
  projData: PropTypes.any,
  projects: PropTypes.array,
};

export default CountryProjectList;
