import FAB from "layouts/FAB";
import PageLayout from "examples/LayoutContainers/PageLayout";
import DashBoardFinance from "examples/Navbars/FaoDashboardNavbar";
import { navActions } from "components/FaoNavigation/FaoNavigation";
import FaoDistrictsReached from "./components";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";

function DistrictsReached(params) {
  const [countryDataList, setCountryDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataFilter, setDataFilter] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const fetchDistricData = async () => {
      const controller = new AbortController();
      const savedFilters = localStorage.getItem("filters");

      setLoading(true);

      let emUrl = "project-dashboard/emergency";
      if (savedFilters !== null && savedFilters !== undefined && savedFilters !== "") {
        emUrl = "project-dashboard/emergency?sector=" + savedFilters;
      }

      const emgFilter = await axiosPrivate.get(emUrl, {
        headers: {
          signal: controller.signal,
        },
      });

      let emgData = emgFilter.data.countryDistribution;

      let countryData = [];
      emgData.map((element) => {
        let country = {
          donorName: element.donorName,
          totalBudget: element.totalBudget,
          totalDelivery: element.totalDelivery,
          project: element.projectList[0],
        };

        countryData.push(country);
      });

      setCountryDataList(countryData);
    };
    fetchDistricData();
  }, [dataFilter]);

  return (
    <PageLayout>
      <DashBoardFinance />
      <MDBox py={1} mt={2} px={2}>
        <FaoDistrictsReached countryData={countryDataList} />
        <FAB actions={navActions()} />
      </MDBox>
    </PageLayout>
  );
}

export default DistrictsReached;
