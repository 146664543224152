/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardNavbar from "examples/Navbars/FaoDashboardNavbar";

// Data

// Dashboard components
import FaoEmergencyMap from "layouts/dashboard/components/FaoEmergencyMap";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import FAB from "layouts/FAB";
import { Card, FormControlLabel, Switch } from "@mui/material";
import CountContent from "./components/CountContent";

import countImg1 from "assets/images/contImg1.jpg";
import districtImg from "assets/images/districts.png";

import FaoProjectList from "./components/FaoProjectList";
import FaoBarChart from "examples/Charts/BarCharts/FaoBarChart";
import FaoProjects from "./components/FaoProjects";
import MDButton from "components/MDButton";
import { navActions } from "components/FaoNavigation/FaoNavigation";

function BeneficiaryList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mapData, setMapData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [countryDataList, setCountryDataList] = useState([]);

  const [dataFilter, setDataFilter] = useState(null);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    // Retrieve data from localStorage when the component mounts
    const savedFilters = localStorage.getItem("filters");
    console.log("Filters from  local storage");
    console.log(savedFilters);
    if (savedFilters) {
      const filterArray = savedFilters.split(",");
      if (filterArray.includes("Agriculture")) {
        setCheckedAgri(true);
      }
      if (filterArray.includes("Fisheries")) {
        setCheckedFisheries(true);
      }
      if (filterArray.includes("Nutrition")) {
        setCheckedNutrition(true);
      }
    }
  }, []);

  useEffect(() => {
    console.log("Data filter in use effect");
    console.log(dataFilter);
    const fetchEmergency = async () => {
      const controller = new AbortController();
      const savedFilters = localStorage.getItem("filters");
      try {
        setLoading(true);
        let emUrl = "project-dashboard/emergency";
        if (savedFilters !== null && savedFilters !== undefined && savedFilters !== "") {
          emUrl = "project-dashboard/emergency?sector=" + savedFilters;
        }

        const response = await axiosPrivate.get(emUrl, {
          headers: {
            signal: controller.signal,
          },
        });

        setData(response.data);
        let emgData = response.data.countryDistribution;

        let proUrl = "project-dashboard/emergency/projects";
        if (savedFilters !== null && savedFilters !== undefined && savedFilters !== "") {
          proUrl = "project-dashboard/emergency/projects?sector=" + savedFilters;
        }

        const proj_response = await axiosPrivate.get(proUrl, {
          headers: {
            signal: controller.signal,
          },
        });

        let countryData = [];
        let projectData = proj_response.data;
        emgData.map((element) => {
          let country = {
            donorName: element.donorName,
            totalBudget: element.totalBudget,
            totalDelivery: element.totalDelivery,
            project: element.projectList[0],
          };

          countryData.push(country);
        });
        setCountryDataList(countryData);
        setProjects(proj_response.data);
        setDistrictData(createDistrictData(response.data.districtDistribution));

        setMapData(getMapData(response.data.budgetMap));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    // dataFilter === null || dataFilter === undefined || dataFilter === ""
    //   ? setDataFilter("Agriculture,Fisheries,Nutrition")
    //   : dataFilter && fetchEmergency();
    fetchEmergency();
  }, [dataFilter]);

  const createDistrictData = (data) => {
    // Extracting years and values
    const districts = Object.keys(data);

    const filledData = [];
    districts.forEach((district) => {
      filledData.push(data[district]);
    });

    const budgets = {
      labels: districts,
      datasets: { label: "District", data: filledData },
    };
    return budgets;
  };

  const getMapData = (data) => {
    // Extracting years and values
    const years = Object.keys(data);
    const values = Object.values(data);

    // Finding the earliest and latest years
    const earliestYear = Math.min(...years);
    const latestYear = Math.max(...years);

    // Filling in missing years with zeros
    const filledData = [];
    for (let year = earliestYear; year <= latestYear; year++) {
      // const dataSet = { year: year.toString(), budgetVal: data[year.toString()] };
      const value = data[year.toString()] || 0;
      filledData.push(value);

      // const dataSet = { year: year.toString(), budgetVal: data[year.toString()] };
      // filledData.push(dataSet);
    }

    // Creating the desired format for the line chart
    const budgets = {
      labels: Array.from({ length: latestYear - earliestYear + 1 }, (_, i) =>
        (earliestYear + i).toString()
      ),
      datasets: { label: "Year", data: filledData },
    };
    return budgets;
  };

  const [checkedAgri, setCheckedAgri] = useState(false);
  const [checkedFisheries, setCheckedFisheries] = useState(false);
  const [checkedNutrition, setCheckedNutrition] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    let filter = "";
    if (checkedAgri) {
      filter += "Agriculture,";
    }
    if (checkedFisheries) {
      filter += "Fisheries,";
    }
    if (checkedNutrition) {
      filter += "Nutrition,";
    }
    if (!initialLoad) {
      filter = filter.endsWith(",") ? filter.slice(0, -1) : filter;
      setDataFilter(filter);
      localStorage.setItem("filters", filter);
    } else {
      setInitialLoad(false);
    }
  }, [checkedAgri, checkedFisheries, checkedNutrition]);

  function handleBack() {
    window.history.back();
  }

  return (
    <PageLayout>
      <DashboardNavbar />
      <Card>
        <MDBox py={1} px={2} display="flex" justifyContent="flex-end">
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <FormControlLabel
              control={
                <Switch
                  checked={checkedAgri}
                  onChange={(event) => setCheckedAgri(event.target.checked)}
                  value="Agriculture"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Agriculture"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={checkedFisheries}
                  onChange={(event) => setCheckedFisheries(event.target.checked)}
                  value="Fisheries"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Fisheries"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={checkedNutrition}
                  onChange={(event) => setCheckedNutrition(event.target.checked)}
                  value="Nutrition"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Nutrition"
            />
            <MDButton
              color="dark"
              onClick={() => {
                handleBack();
              }}
            >
              Back
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      <MDBox py={1} mt={2} px={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <CountContent
                icon={countImg1}
                title="Donor Details"
                count={data.countryDistribution ? data.countryDistribution.length : null}
                loading={loading}
                linkTo="/emergency-list"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <CountContent
                icon={countImg1}
                title="Beneficiaries Per Initiative"
                count={data.countryDistribution ? data.countryDistribution.length : null}
                loading={loading}
                linkTo="#"
                disable="true"
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <CountContent
                icon={districtImg}
                title="District Details"
                count={
                  data.districtDistribution ? Object.keys(data.districtDistribution).length : 0
                }
                loading={loading}
                linkTo="/districtsReached"
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            {!loading && (
              <FaoProjectList projects={projects} title="Emergency Projects" origin="emergency" />
            )}{" "}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {!loading && (
              <FaoProjects
                projData={data.beneficiariesByResponseModality}
                projects={projects}
                loading={loading}
                origin="emergency"
              />
            )}{" "}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            {!loading && (
              <FaoProjects
                projData={data.distributionByItem}
                projects={projects}
                loading={loading}
                origin="items"
              />
            )}{" "}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                {!loading && <FaoEmergencyMap districtDistribution={data.districtDistribution} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={5} px={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {!loading && (
              <FaoBarChart
                color="info"
                title="Budget Map"
                chart={{ labels: mapData.labels, datasets: mapData.datasets }}
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
      <FAB actions={navActions()} />
    </PageLayout>
  );
}

export default BeneficiaryList;
