import data from "../data";
import MDBox from "components/MDBox";
import FaoDataTable from "../FaoDataTable";
import MDButton from "components/MDButton";
import { useState, useEffect } from "react";
import PropTypes, { any } from "prop-types";
import MDTypography from "components/MDTypography";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Card, FormControlLabel, Switch, Grid } from "@mui/material";
import FaoEmergencyMap from "layouts/dashboard/components/FaoEmergencyMap";
import Chart from "react-google-charts";
import FaoDonarContribution from "layouts/dashboard/components/FaoCountryList";

import countImg1 from "assets/images/contImg1.jpg";
import districtImg from "assets/images/districts.png";
import benificiaryImg from "assets/images/navigation/hr.png";
import CountContent from "layouts/emergency/components/CountContent";

function FaoDistrictsReached({ countryData }) {
  const [checkedAgri, setCheckedAgri] = useState(false);
  const [checkedFisheries, setCheckedFisheries] = useState(false);
  const [checkedNutrition, setCheckedNutrition] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [dataFilter, setDataFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [districtData, setDistrictData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [clickedRowIndex, setClickedRowIndex] = useState(null);
  const [districtDistribution, setDistrictDistribution] = useState([]);

  useEffect(() => {
    let filter = "";
    if (checkedAgri) {
      filter += "Agriculture,";
    }
    if (checkedFisheries) {
      filter += "Fisheries,";
    }
    if (checkedNutrition) {
      filter += "Nutrition,";
    }
    if (!initialLoad) {
      filter = filter.endsWith(",") ? filter.slice(0, -1) : filter;
      setDataFilter(filter);
      localStorage.setItem("filters", filter);
    } else {
      setInitialLoad(false);
    }
  }, [checkedAgri, checkedFisheries, checkedNutrition]);

  const clearFilterData = () => {
    setClickedRowIndex(null);
    // fetchEmergency();
  };

  function handleBack() {
    window.history.back();
  }
  useEffect(() => {
    const fetchDistricData = async () => {
      const controller = new AbortController();
      const savedFilters = localStorage.getItem("filters");
      try {
        setLoading(true);
        let districUrl = "/project-dashboard/emergency/all-districts-reached";
        if (savedFilters !== null && savedFilters !== undefined && savedFilters !== "") {
          districUrl = "/project-dashboard/emergency/districts-reached?sector=" + savedFilters;
        }
        const response = await axiosPrivate.get(districUrl, {
          headers: {
            signal: controller.signal,
          },
        });
        setDistrictData(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDistricData();
  }, [dataFilter]);
  const districtObj = {};
  districtData.forEach((item) => {
    const key = item[0];
    const value = item[1];
    districtObj[key] = value;
  });

  const options = {
    chart: {
      title: "Beneficiaries reached by District",
      //subtitle: "district",
    },
  };

  const barchardata = ["district", "beneficiary count"];

  const newArray = [];
  newArray.push(barchardata);
  districtData?.forEach((item) => {
    newArray.push(item);
  });

  const onDonorRowClick = (donor, key) => {
    setClickedRowIndex(key);
    // /project-dashboard/donor/districtDistribution
    const fetchDistrictDistributionData = async (donorName) => {
      const controller = new AbortController();
      setLoading(true);
      try {
        let emUrl = `/project-dashboard/donor/districtDistribution?donor=${donorName}`;

        const response = await axiosPrivate.get(emUrl, {
          headers: {
            signal: controller.signal,
          },
        });

        setDistrictData(Object.entries(response.data));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDistrictDistributionData(donor?.name);
  };

  console.log({ newArray });

  const { columns, rows } = data(districtData);

  return (
    <div>
      <Card style={{ height: "auto", overflow: "auto" }}>
        {/* <Card> */}
        <MDBox py={1} px={2} display="flex" justifyContent="flex-end">
          <MDBox display="flex" alignItems="center" lineHeight={0}>
            <FormControlLabel
              control={
                <Switch
                  checked={checkedAgri}
                  onChange={(event) => setCheckedAgri(event.target.checked)}
                  value="Agriculture"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Agriculture"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={checkedFisheries}
                  onChange={(event) => setCheckedFisheries(event.target.checked)}
                  value="Fisheries"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Fisheries"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={checkedNutrition}
                  onChange={(event) => setCheckedNutrition(event.target.checked)}
                  value="Nutrition"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Nutrition"
            />
            <MDButton
              color="dark"
              onClick={() => {
                handleBack();
              }}
            >
              Back
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      <MDBox py={1} mt={2} px={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <CountContent
                icon={countImg1}
                title="Donors Details"
                count={0}
                loading={false}
                linkTo="/emergency-list"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <CountContent
                icon={benificiaryImg}
                title="Beneficiaries Per Initiative"
                count={0}
                loading={false}
                linkTo="/beneficiary-list"
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <CountContent
                icon={districtImg}
                title="District Details"
                count={0}
                loading={false}
                linkTo="#"
                disable="true"
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <MDBox>
                {!loading && (
                  <FaoDonarContribution
                    countryData={countryData}
                    showDelivery={false}
                    onDonorRowClick={onDonorRowClick}
                    clickedRowIndex={clickedRowIndex}
                    clearFilterData={clearFilterData}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <MDTypography variant="h6" gutterBottom>
                  District Distribution
                </MDTypography>
              </MDBox>
              <MDBox>
                {!loading && (
                  <FaoDataTable
                    table={{ columns, rows }}
                    showTotalEntries={false}
                    isSorted={false}
                    noEndBorder
                    entriesPerPage={false}
                    onRowClick={onDonorRowClick}
                    clickedRowIndex={clickedRowIndex}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
          <Grid></Grid>
          <Grid item xs={12} md={4} lg={4}>
            <div>{!loading && <FaoEmergencyMap districtDistribution={districtObj} />}</div>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <Card>
          <Chart
            chartType="Bar"
            width="100%"
            style={{ padding: "20px" }}
            height="400px"
            data={newArray}
            options={options}
          />
        </Card>
      </MDBox>
    </div>
  );
}

FaoDistrictsReached.propTypes = {
  countryData: PropTypes.any,
  showDelivery: PropTypes.bool,
  onDonorRowClick: any,
  clickedRowIndex: any,
  clearFilterData: any,
};

export default FaoDistrictsReached;
