import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import MDAvatar from "components/MDAvatar";
import AvatarImg from "assets/images/digitalinitiativeImg.jpg";
import { Button } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function DigitalInitiativeCard({ title, description, image, link }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card>
      <CardHeader
        avatar={
          image ? (
            <MDAvatar src={AvatarImg} />
          ) : (
            <Avatar sx={{ bgcolor: red[500] }}>{title[0]}</Avatar>
          )
        }
        title={<Typography variant="h5">{title}</Typography>}
      />
      <CardMedia padding="15px" component="img" height="250" image={image} alt={title} />
      <CardContent></CardContent>
      <CardActions disableSpacing>
        {link && (
          <Button variant="outlined" color="primary" target="_blank" href={link}>
            <Typography variant="button" fontWeight={700} color="ButtonText">
              Click Here
            </Typography>
          </Button>
        )}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>{description}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

DigitalInitiativeCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
};

export default DigitalInitiativeCard;
