// @mui material components
import Grid from "@mui/material/Grid";

// Dashboard components
import PageLayout from "examples/LayoutContainers/PageLayout";

import FrontPageLeft from "../FrontPageLeft";
import DashboardNavbar from "examples/Navbars/BeginNavbar";

import emergency from "../../assets/images/navigation/emegency.png";
import country from "../../assets/images/navigation/country.png";
import digital from "../../assets/images/navigation/digital.png";
import result from "../../assets/images/navigation/result.png";
import finance from "../../assets/images/navigation/finance.png";
import hr from "../../assets/images/navigation/hr.png";

import BeginCard from "./components/BeginCard";
import { Divider } from "@mui/material";
import OZDivider from "components/OZDivider";

function Begin() {
  return (
    <PageLayout>
      <DashboardNavbar />
      {/* <MDBox mt={3} className="begin"> */}
      <Grid container spacing={1}>
        <Grid item xs={12} md={8} lg={7}>
          <FrontPageLeft />
        </Grid>
        <OZDivider orientation="vertical" />
        <Grid item xs={12} md={2} lg={4} style={{ paddingLeft: "70px", paddingTop: "30px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <BeginCard image={emergency} title="Emergency Programs" linkTo="/emergency" />
            </Grid>
            <Grid item xs={12}>
              <BeginCard
                image={country}
                title="Country Program Delivery"
                linkTo="/country-program"
              />
            </Grid>
            <Grid item xs={12}>
              <BeginCard image={result} title="Delivery of Results" linkTo="/delivery-result" />
            </Grid>
            <Grid item xs={12}>
              <BeginCard image={digital} title="Digital Initiatives" linkTo="/digital-initiative" />
            </Grid>
          </Grid>
          <Divider orientation="horizontal" flexItem style={{ margin: "15px 2px" }} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <BeginCard image={finance} title="Finance and procurement" linkTo="/finance" />
            </Grid>
            <Grid item xs={12}>
              <BeginCard image={hr} title="Human Resources" linkTo="/hr" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </MDBox> */}
    </PageLayout>
  );
}

export default Begin;
