import { Card, CircularProgress, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import React, { useEffect, useState } from "react";
import { PieChart, Pie, Line } from "recharts";

export default function DeliverableTypes() {
  const [loading, setLoading] = useState(true);
  const [groupData, setGroupData] = useState([]);
  const [subGroupData, setSubGroupData] = useState([]);

  const [checkedEmergency, setCheckedEmergency] = useState(false);
  const [checkedOther, setCheckedOther] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    fetchFrameworkData();
  }, [checkedEmergency, checkedOther]);

  const fetchFrameworkData = async () => {
    const controller = new AbortController();
    try {
      setLoading(true);
      const response = await axiosPrivate.get(
        `four-better-mappings/getBudgetData?isEmergency=${checkedEmergency}&isOther=${checkedOther}`,
        {
          headers: {
            signal: controller.signal,
          },
        }
      );
      console.log("four better mappings");
      console.log(response.data);

      const inputObject = response.data;
      const data01 = [];
      const data02 = [];
      // Calculate sum for each group and populate data01
      for (const group in inputObject) {
        const sum = Object.values(inputObject[group]).reduce((acc, value) => acc + value, 0);
        data01.push({ name: group, value: sum });

        // Populate data02 with individual subgroup values
        for (const subgroup in inputObject[group]) {
          data02.push({ name: subgroup, value: inputObject[group][subgroup] });
        }
      }

      console.log(data01);
      console.log(data02);

      setGroupData(data01);
      setSubGroupData(data02);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card style={{ height: "650px", overflow: "auto" }}>
      <MDBox justifyContent="space-between" alignItems="center" p={2}>
        <Typography>Buddget Distribution Over Four Betters (Ongoing)</Typography>
        <MDBox py={1} px={2} display="flex">
          <FormControlLabel
            control={
              <Switch
                checked={checkedEmergency}
                onChange={(event) => setCheckedEmergency(event.target.checked)}
                value="EMERGENCY"
                style={{
                  color: "blue",
                }}
              />
            }
            label="Emergency"
          />
          <FormControlLabel
            control={
              <Switch
                checked={checkedOther}
                onChange={(event) => setCheckedOther(event.target.checked)}
                value="OtherDevProjects"
                style={{
                  color: "blue",
                }}
              />
            }
            label="Development Projects/ TCP"
          />
        </MDBox>
        <br />
        {!loading ? (
          <PieChart width={700} height={500}>
            <Pie
              data={groupData}
              dataKey="value"
              cx={350}
              cy={235}
              outerRadius={120}
              fill="#8884d8"
              label={({ name }) => name}
            />
            <Pie
              data={subGroupData}
              dataKey="value"
              display={"name"}
              cx={350}
              cy={235}
              innerRadius={200}
              outerRadius={240}
              fill="#82ca9d"
              label={({ name }) => name}
              colors={["#82ca9d", "#8884d8", "#ff7f0e", "#ffbb78", "#2ca02c", "#98df8a"]}
            />
          </PieChart>
        ) : (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </MDBox>
    </Card>
  );
}
