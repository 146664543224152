import { Analytics, Dashboard, Home, Info, MonetizationOn, Groups } from "@mui/icons-material";

const faoNavigation = (type) => {
  switch (type) {
    case "H":
      window.location.href = "/begin";
      break;
    case "E":
      window.location.href = "/emergency";
      break;
    case "P":
      window.location.href = "/country-program";
      break;
    case "R":
      window.location.href = "/delivery-result";
      break;
    case "F":
      window.location.href = "/finance";
      break;
    case "HR":
      window.location.href = "/hr";
      break;
    default:
      window.location.href = "/delivery-result";
  }
};

const navActions = () => {
  const actions = [
    { label: "Home", icon: <Home />, onClick: () => faoNavigation("H") },
    { label: "Emergency Dashboad", icon: <Dashboard />, onClick: () => faoNavigation("E") },
    { label: "Program Dashboad", icon: <Analytics />, onClick: () => faoNavigation("P") },
    { label: "Results Dashboad", icon: <Info />, onClick: () => faoNavigation("R") },
    { label: "Finance Dashboad", icon: <MonetizationOn />, onClick: () => faoNavigation("F") },
    { label: "HR Dashboad", icon: <Groups />, onClick: () => faoNavigation("HR") },
  ];
  return actions;
};

export { navActions };
