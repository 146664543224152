/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import cash from "assets/images/small-logos/money.svg";
import fertilizer from "assets/images/small-logos/agri1.svg";
import people from "assets/images/small-logos/people.svg";
import land from "assets/images/small-logos/land.svg";
import { Avatar } from "@mui/material";

export default function data(values, ppa) {
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Avatar {...stringAvatar(image)} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>

        <Tooltip title={email} placement="right-start">
          <MDTypography variant="text">{limit(email, 80)}</MDTypography>
        </Tooltip>
      </MDBox>
    </MDBox>
  );

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  function limit(string = "", limit = 0) {
    if (string.length < limit) return string;

    return string.substring(0, limit - 4) + "...";
  }

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return {
    columns: [
      { Header: "Project", accessor: "projectCode", align: "left" },
      {
        Header: "Results",
        accessor: "strategicFrameworkOrganizationResult",
        align: "left",
      },
      { Header: "Budget", accessor: "totalBudgetFPMIS", align: "right" },
    ],

    rows: values.map((row) => {
      return {
        projectCode: (
          <Author
            image={ppa.substring(0, 1) + " " + ppa.substring(1)}
            name={row.projectSymbol}
            email={row.projectTitle}
          />
        ),
        projectTitle: row.projectTitle,
        strategicFrameworkOrganizationResult: row.strategicFrameworkOrganizationResult,
        totalBudgetFPMIS: USDollar.format(row.totalBudgetFPMIS),
      };
    }),
  };
}
