/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Overview page components
import Header from "./components/Header";

import PropTypes from "prop-types";
import PageLayout from "examples/LayoutContainers/PageLayout";

import projData from "./data";

import digitalInitiativeImg from "assets/images/digitalinitiativeImg.jpg";
import DigitalInitiativeCard from "./components/DigitalInitiativeCard";

function DigitalInitiative() {
  const projectData = projData();

  return (
    <PageLayout>
      {/* <DashboardNavbar /> */}
      <MDBox mb={2} />
      <Header
        projectName="Digital Initiatives"
        projectTitle="The FAO's digital initiatives in Sri Lanka encompass a range of innovative solutions aimed at enhancing agricultural practices, education, market access, and data collection. These initiatives include web and mobile applications like the Digital Agriculture web portal, SmartAI for extension officers, Smart Farmer app for farmers, Nena Wagawa LMS for paddy cultivation education, HARTI's price data collection and consumer apps, S.E.E.D Hub for climate-resilient farming information, and the Entrepreneurial School Garden Programme for educational institutions. Each platform is designed to provide crucial, real-time information and services to its users, whether they are farmers, educators, students, or consumers, ensuring improved efficiency, productivity, and knowledge sharing in the agricultural sector."
        media={digitalInitiativeImg}
      >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={2}>
            {projectData?.map((project) => {
              return (
                // eslint-disable-next-line react/jsx-key
                <Grid item xs={12} md={6} xl={4}>
                  <DigitalInitiativeCard
                    title={project.topic}
                    description={project.description}
                    image={project.image}
                    link={project.link}
                  />
                </Grid>
              );
            })}
          </Grid>
        </MDBox>
        {/* <FAB actions={navActions()} /> */}
      </Header>
    </PageLayout>
  );
}

DigitalInitiative.propTypes = {
  props: PropTypes.string,
};

export default DigitalInitiative;
