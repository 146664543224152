import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import React, { useEffect, useState } from "react";

import useAxiosPrivate from "hooks/useAxiosPrivate";

import PropTypes from "prop-types";

import { Chart } from "react-google-charts";

const GanttChart = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const axiosPrivate = useAxiosPrivate();

  const columns = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
  ];

  useEffect(() => {
    const fetchFrameworkData = async () => {
      const controller = new AbortController();
      try {
        setLoading(true);
        const response = await axiosPrivate.get(`country/project/${id}/gantt-chart`, {
          headers: {
            signal: controller.signal,
          },
        });

        const dataList = response.data;

        const rows = dataList.map((item, index) => {
          // Calculate duration in milliseconds
          const startDate = new Date(item.startDate);
          const endDate = new Date(item.endDate);
          const durationMilliseconds = endDate.getTime() - startDate.getTime();

          // Convert duration to days
          const durationDays = durationMilliseconds / (1000 * 60 * 60 * 24);

          return [
            item.taskId, // Task Name
            item.taskName, // Task Name
            item.resource, // Resource
            startDate, // Start Date
            endDate, // End Date
            null, // Duration in days
            null, // Percent Complete
            null, // item.dependencies, // Dependencies
          ];
        });

        const rowData = [columns, ...rows];

        setData(rowData);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchFrameworkData();
  }, []);

  const options = {
    gantt: {
      criticalPathEnabled: true,
      criticalPathStyle: {
        stroke: "#e64a19",
        strokeWidth: 5,
      },
    },
  };

  const handleClick = (event) => {
    console.log(event);
  };

  const handleSelect = (event) => {
    console.log("SELECT");
  };

  return (
    <div>
      <h5>Work Plan - Gantt Chart</h5>

      {!loading && (
        <Chart
          width="99%"
          height="400px"
          chartType="Gantt"
          loader={<div>Loading Chart</div>}
          data={data}
          options={options}
          rootProps={{ "data-testid": "2" }}
          click={handleClick}
          select={handleSelect}
          chartEvents={[
            {
              eventName: "select",
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();

                console.log(selection);
              },
            },
          ]}
        />
      )}
    </div>
  );
};

GanttChart.propTypes = {
  id: PropTypes.any,
};

export default GanttChart;
