/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components

// Overview page components
import Header from "./components/Header";

// Data

// Images

import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Framework from "../components/framework";

import FAB from "layouts/FAB";
import CountryFramework from "layouts/countryProgram/components/framework";
import { navActions } from "components/FaoNavigation/FaoNavigation";

function Result() {
  const { id } = useParams();
  const { type } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEmergency = async () => {
      const controller = new AbortController();
      try {
        setLoading(true);

        const url =
          type === "EF" ? "project-dashboard/emergency/projects" : "project-dashboard/projects";
        const response = await axiosPrivate.get(`${url}/${id}`, {
          headers: {
            signal: controller.signal,
          },
        });
        setData(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchEmergency();
  }, []);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <PageLayout>
      {/* <DashboardNavbar /> */}
      <MDBox mb={2} />
      <Header
        projectName={data.projectName}
        projectTitle={data.projectTitle}
        projectSymbol={data.projectSymbol}
      >
        <MDBox mt={5} mb={3}>
          {type === "EF" ? <Framework id={id} /> : <CountryFramework id={id} />}
        </MDBox>
      </Header>
      <FAB actions={navActions()} />
    </PageLayout>
  );
}

Result.propTypes = {
  props: PropTypes.string,
};

export default Result;
