/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

// Images
import Flag from "react-world-flags";

export default function data(countryData) {
  const Author = ({ countryCode, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Flag code={countryCode} height="30" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>

        <Tooltip title={email} placement="right-start">
          <MDTypography variant="text">{limit(email, 100)}</MDTypography>
        </Tooltip>
      </MDBox>
    </MDBox>
  );

  function limit(string = "", limit = 0) {
    if (string.length < limit) return string;

    return string.substring(0, limit - 4) + "...";
  }

  function getDeliveryStatus(status) {
    switch (status) {
      case "OC":
        return "Op Closed";
      case "FC":
        return "Fi Closed";
      case "AC":
        return "Ac Completed";
      case "OA":
        return "Op Active";
    }
  }

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function getCountryCode(country) {
    switch (country) {
      case "Canada":
        return "can";
      case "United States":
        return "usa";
      case "UK" || "United Kingdom":
        return "gbr";
      case "Germany: BMZ" || "Germany":
        return "ger";
      case "Japan":
        return "jpn";
      case "Australia":
        return "aus";
      case "Netherlands: DGIS" || "Netherlands":
        return "ned";
      case "Norway: NORAD" || "Norway":
        return "nor";
      case "Sweden":
        return "swe";
      case "Switzerland: SDC" || "Switzerland":
        return "swi";
      case "Denmark: DANIDA" || "Denmark":
        return "den";
      case "Italy: AICS" || "Italy":
        return "ita";
      case "New Zealand":
        return "nzl";
      case "European Union":
        return "EU";
      case "China":
        return "chn";
      default:
        "sl";
    }
  }

  return {
    columns: [
      { Header: "Donor", accessor: "donor", width: "45%", align: "left" },
      { Header: "Budget", accessor: "totalBudget", align: "right" },
      { Header: "Expenditure", accessor: "totalDelivery", align: "right" },
      { Header: "Delivery %", accessor: "deliveryPercentage", align: "right" },
      { Header: "Actions", accessor: "actions", align: "center" },
    ],

    rows:
      countryData &&
      countryData.map((row) => {
        return {
          donor: (
            <Author
              countryCode={getCountryCode(row.donorName)}
              name={row.donorName ? row.donorName : "Not Available"}
              email={row.donorName ? row.projectList?.join(", ") : ""}
            />
          ),
          totalBudget: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {USDollar.format(row.totalBudget)}
            </MDTypography>
          ),
          totalDelivery: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {USDollar.format(row.totalDelivery)}
            </MDTypography>
          ),
          deliveryPercentage: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {((row.totalDelivery / row.totalBudget) * 100).toFixed(2)}%
            </MDTypography>
          ),
          status: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {getDeliveryStatus(row.status)}
            </MDTypography>
          ),
          actions: (
            <MDButton
              component={Link}
              to={`/donor-profile/${encodeURIComponent(row.entityNumber)}`}
              color="dark"
            >
              Details
            </MDButton>
          ),
        };
      }),
  };
}
