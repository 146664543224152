import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";

function FrontPageLeft() {
  return (
    <MDBox px={1}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h4>FAOLK Program Update</h4>
          </div>
        </Grid>

        <Grid item xs={12} md={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <video width="720" height="420" controls>
              <source
                src="https://fao-dashboard.s3.ap-south-1.amazonaws.com/fao.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default FrontPageLeft;
