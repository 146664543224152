import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { Save } from "@mui/icons-material";
import { json } from "d3";

function ProjectInformation() {
  const { state } = useLocation();

  const [formData, setFormData] = useState(state?.target || {});
  const [ppaCounter, setPPACounter] = useState(1);
  const [projectTechnicalStaffCounter, setProjectTechnicalStaffCounter] = useState(1);
  const [ppaList, setPPAList] = useState([{ id: 1, ppaArea: "", ppaPercentage: "" }]);
  const [projectTechnicalStaffList, setProjectTechnicalStaffList] = useState([
    { id: 1, position: "", name: "", contractPeriod: "" },
  ]);

  const [fourBetter, setFourBetter] = useState({ BP: false, BE: false, BL: false, BN: false });
  const [deliveryEstimates, setDeliveryEstimates] = useState({
    value_2023: "",
    value_2024: "",
    value_2025: "",
    value_2026: "",
  });
  const [actulaAnnualDelivery, setActualAnnualDelivery] = useState({
    value_2023: "",
    value_2024: "",
    value_2025: "",
    value_2026: "",
  });

  const handleDeliveryEstimates = (name, value) => {
    debugger;
    if (name == "2023") {
      deliveryEstimates.value_2023 = value;
    } else if (name == "2024") {
      deliveryEstimates.value_2024 = value;
    } else if (name == "2025") {
      deliveryEstimates.value_2025 = value;
    } else if (name == "2026") {
      deliveryEstimates.value_2026 = value;
    }
    setDeliveryEstimates(deliveryEstimates);
  };

  const handleActualAnnualDelivery = (name, value) => {
    debugger;
    if (name == "2023") {
      actulaAnnualDelivery.value_2023 = value;
    } else if (name == "2024") {
      actulaAnnualDelivery.value_2024 = value;
    } else if (name == "2025") {
      actulaAnnualDelivery.value_2025 = value;
    } else if (name == "2026") {
      actulaAnnualDelivery.value_2026 = value;
    }
    setActualAnnualDelivery(actulaAnnualDelivery);
  };

  const handleChange = (value, target) => {
    debugger;
    setFormData((current = {}) => {
      let newData = { ...current };
      newData[target] = value;
      return newData;
    });
  };

  const handleFormSubmit = () => {
    debugger;
    formData.PPA = ppaList;
    formData.projectTechnicalStaffList = projectTechnicalStaffList;
    formData.fourBetter = fourBetter;
    formData.deliveryEstimates = deliveryEstimates;
    formData.actulaAnnualDelivery = actulaAnnualDelivery;
    formData.deliveryEstimates = deliveryEstimates;
    formData.actulaAnnualDelivery = actulaAnnualDelivery;

    var a = formData;
  };

  const handleFourBetter = (name, value) => {
    debugger;
    if (name == "BP") {
      fourBetter.BP = value;
    } else if (name == "BE") {
      fourBetter.BE = value;
    } else if (name == "BL") {
      fourBetter.BL == value;
    } else if (name == "BN") {
      fourBetter.BN == value;
    }
    setFourBetter(fourBetter);
  };

  const handlePPAChange = (value, target, index) => {
    debugger;
    if (target == "ppaArea") {
      // eslint-disable-next-line prettier/prettier
      var isAlreadyAdded = ppaList.some((obj) => obj.id == index + 1);
      if (isAlreadyAdded) {
        // eslint-disable-next-line prettier/prettier
        var ppaObject = ppaList.filter((a) => a.id == index + 1);
        ppaObject[0].ppaArea = value;
        var newPPAlist = ppaList.filter((a) => a.id != index + 1);
        newPPAlist.push(ppaObject[0]);
        var sortedList = [...newPPAlist].sort((a, b) => a.id - b.id);
        setPPAList(sortedList);
      } else {
        ppaList.push({ id: index, ppaArea: value, ppaPercentage: "" });
        setPPAList(ppaList);
      }
    } else if (target == "ppaAreaPercentage") {
      // eslint-disable-next-line prettier/prettier
      var isAlreadyAdded = ppaList.some((obj) => obj.id == index + 1);
      if (isAlreadyAdded) {
        // eslint-disable-next-line prettier/prettier
        var ppaObject = ppaList.filter((a) => a.id == index + 1);
        ppaObject[0].ppaPercentage = value;
        var newPPAlist = ppaList.filter((a) => a.id != index + 1);
        newPPAlist.push(ppaObject[0]);
        var sortedList = [...newPPAlist].sort((a, b) => a.id - b.id);
        setPPAList(sortedList);
      } else {
        ppaList.push({ id: index, ppaArea: "", ppaPercentage: value });
        setPPAList(ppaList);
      }
    }
  };

  const handleProjectTechnicalStaff = (value, target, index) => {
    debugger;
    if (target == "position") {
      // eslint-disable-next-line prettier/prettier
      var isAlreadyAdded = projectTechnicalStaffList.some((obj) => obj.id == index + 1);
      if (isAlreadyAdded) {
        // eslint-disable-next-line prettier/prettier
        var staffObject = projectTechnicalStaffList.filter((a) => a.id == index + 1);
        staffObject[0].position = value;
        var newProjectTechnicalStaffList = projectTechnicalStaffList.filter(
          (a) => a.id != index + 1
        );
        newProjectTechnicalStaffList.push(staffObject[0]);
        var sortedList = [...newProjectTechnicalStaffList].sort((a, b) => a.id - b.id);
        setProjectTechnicalStaffList(sortedList);
      } else {
        projectTechnicalStaffList.push({
          id: index,
          position: value,
          name: "",
          contractPeriod: "",
        });
        setProjectTechnicalStaffList(projectTechnicalStaffList);
      }
    } else if (target == "name") {
      // eslint-disable-next-line prettier/prettier
      var isAlreadyAdded = projectTechnicalStaffList.some((obj) => obj.id == index + 1);
      if (isAlreadyAdded) {
        // eslint-disable-next-line prettier/prettier
        var staffObject = projectTechnicalStaffList.filter((a) => a.id == index + 1);
        staffObject[0].name = value;
        var newProjectTechnicalStaffList = projectTechnicalStaffList.filter(
          (a) => a.id != index + 1
        );
        newProjectTechnicalStaffList.push(staffObject[0]);
        var sortedList = [...newProjectTechnicalStaffList].sort((a, b) => a.id - b.id);
        setProjectTechnicalStaffList(sortedList);
      } else {
        projectTechnicalStaffList.push({
          id: index,
          position: "",
          name: value,
          contractPeriod: "",
        });
        setProjectTechnicalStaffList(projectTechnicalStaffList);
      }
    } else if (target == "contractPeriod") {
      // eslint-disable-next-line prettier/prettier
      var isAlreadyAdded = projectTechnicalStaffList.some((obj) => obj.id == index + 1);
      if (isAlreadyAdded) {
        // eslint-disable-next-line prettier/prettier
        var staffObject = projectTechnicalStaffList.filter((a) => a.id == index + 1);
        staffObject[0].contractPeriod = value;
        var newProjectTechnicalStaffList = projectTechnicalStaffList.filter(
          (a) => a.id != index + 1
        );
        newProjectTechnicalStaffList.push(staffObject[0]);
        var sortedList = [...newProjectTechnicalStaffList].sort((a, b) => a.id - b.id);
        setProjectTechnicalStaffList(sortedList);
      } else {
        projectTechnicalStaffList.push({
          id: index,
          position: "",
          name: "",
          contractPeriod: value,
        });
        setProjectTechnicalStaffList(projectTechnicalStaffList);
      }
    }
  };

  const handleProjecttechnicalStaffChange = (value, target) => {};

  const handleAddPPAClick = () => {
    debugger;
    setPPACounter(ppaCounter + 1);
    ppaList.push({ id: ppaCounter + 1, ppaArea: "", ppaPercentage: "" });
  };

  const handleProjectTechnicalStaffClick = () => {
    setProjectTechnicalStaffCounter(projectTechnicalStaffCounter + 1);
    projectTechnicalStaffList.push({
      id: projectTechnicalStaffCounter + 1,
      position: "",
      name: "",
      contractPeriod: "",
    });
  };

  return (
    <Card>
      <MDBox pt={4} pb={3} px={9}>
        <MDTypography variant="h6" fontWeight="medium">
          Project Information
        </MDTypography>
        <MDBox component="form" role="form">
          <MDBox pt={2} pb={1} px={1} gap={20} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblCountryName">Country Name</FormLabel>
              <MDInput
                type="text"
                variant="outlined"
                id="countryName"
                value={formData?.countryName || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "countryName")}
              />
            </MDBox>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblProjectStatus">Project status</FormLabel>
              <MDInput
                type="text"
                variant="outlined"
                id="projectStatus"
                value={formData?.projectStatus || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "projectStatus")}
              />
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} display="flex" gap={20}>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblEntityNumber">Entity Number</FormLabel>
              <MDInput
                type="text"
                variant="outlined"
                id="entityNumber"
                value={formData?.entityNumber || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "entityNumber")}
              />
            </MDBox>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblShortName">Short Name</FormLabel>
              <MDInput
                type="text"
                variant="outlined"
                id="shortName"
                value={formData?.shortName || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "shortName")}
              />
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} display="flex" gap={20}>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblProjectCode">Project Code</FormLabel>
              <MDInput
                type="text"
                variant="outlined"
                id="projectCode"
                value={formData?.projectCode || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "projectCode")}
              />
            </MDBox>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblStartDate">Start Date</FormLabel>
              <MDInput
                type="date"
                variant="outlined"
                id="startDate"
                value={formData?.startDate || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "startDate")}
              />
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} display="flex" gap={20}>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblProjectTitle">Project Title</FormLabel>
              <MDInput
                type="text"
                variant="outlined"
                id="projectTitle"
                value={formData?.projectTitle || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "projectTitle")}
              />
            </MDBox>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblEndDate">End Date</FormLabel>
              <MDInput
                type="date"
                variant="outlined"
                id="endDate"
                value={formData?.endDate || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "endDate")}
              />
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} display="flex" gap={16}>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblProjectObjective">Project Objective</FormLabel>
              <MDInput
                type="text"
                variant="outlined"
                id="projectObjective"
                value={formData?.projectObjective || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "projectObjective")}
              />
            </MDBox>
            <MDBox gap={2} display="flex">
              <FormControl>
                <FormLabel id="lblEmergency">Emergency</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  id="emergency"
                  defaultValue="female"
                  name="rbg_Emergency"
                  value={formData?.emergency || ""}
                  onChange={(e) => handleChange(e?.target?.value || "", "emergency")}
                >
                  <FormControlLabel value="true" control={<Radio />} label="Yes" />
                  <FormControlLabel value="false" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} display="flex" gap={20}>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblCountryCode">Country Code</FormLabel>
              <MDInput
                type="text"
                variant="outlined"
                id="countryCode"
                value={formData?.countryCode || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "countryCode")}
              />
            </MDBox>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblFourBetter">Four better</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="BP"
                  id="fb-BP"
                  value={fourBetter.BP || ""}
                  onChange={(e) => {
                    handleFourBetter("BP", e.target.checked);
                  }}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="BE"
                  id="fb-BE"
                  value={fourBetter.BE || ""}
                  onChange={(e) => {
                    handleFourBetter("BE", e.target.checked);
                  }}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="BL"
                  id="fb-BL"
                  value={fourBetter.BL || ""}
                  onChange={(e) => {
                    handleFourBetter("BL", e.target.checked);
                  }}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="BN"
                  id="fb-BN"
                  value={fourBetter.BN || ""}
                  onChange={(e) => {
                    handleFourBetter("BN", e.target.checked);
                  }}
                />
              </FormGroup>
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} display="flex" gap={24}>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblBudget">Budget</FormLabel>
              <MDInput
                type="text"
                label="Budget"
                variant="outlined"
                id="budget"
                value={formData?.budget || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "budget")}
              />
            </MDBox>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblPPA">PPA</FormLabel>
              <table>
                {Array.from(Array(ppaCounter)).map((c, index) => {
                  return (
                    <tr id={c} key={c} data-index={c}>
                      <Select
                        sx={{ minWidth: 270, minHeight: 30 }}
                        id="ppaArea"
                        value={ppaList[index].ppaArea || ""}
                        label="PPA Area"
                        onChange={(e) => handlePPAChange(e?.target?.value || "", "ppaArea", index)}
                      >
                        <MenuItem value={10}>PPA1</MenuItem>
                        <MenuItem value={20}>PPA2</MenuItem>
                        <MenuItem value={30}>PPA3</MenuItem>
                      </Select>
                      <Select
                        sx={{ minWidth: 80, minHeight: 30 }}
                        id="ppaAreaPercentage"
                        value={ppaList[index].ppaPercentage || ""}
                        label="PPA Area Percentage%"
                        onChange={(e) =>
                          handlePPAChange(e?.target?.value || "", "ppaAreaPercentage", index)
                        }
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <IconButton color="primary" aria-label="add">
                        <AddIcon color="black" onClick={handleAddPPAClick}></AddIcon>
                      </IconButton>
                    </tr>
                  );
                })}
              </table>
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} gap={26} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblDoner">Doner</FormLabel>
              <MDInput
                type="text"
                variant="outlined"
                id="doner"
                value={formData?.doner || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "doner")}
              />
            </MDBox>
            <MDBox gap={2} display="flex">
              <FormLabel id="lblFundingSource">Funding Source</FormLabel>
              <MDInput
                type="text"
                variant="outlined"
                id="fundingSource"
                value={formData?.fundingSource || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "fundingSource")}
              />
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} gap={26} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblTotalDelivery">Total Delivery</FormLabel>
              <MDInput
                type="text"
                variant="outlined"
                id="totalDelivery"
                value={formData?.totalDelivery || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "totalDelivery")}
              />
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} gap={26} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblDeliveryEstimates">Delivery Estimates</FormLabel>
              <MDInput
                type="text"
                label="2023"
                variant="outlined"
                id="deliveryEstimates.value_2023"
                value={deliveryEstimates?.value_2023 || ""}
                onChange={(e) => handleDeliveryEstimates("2023", e?.target?.value || "")}
              />
              <MDInput
                type="text"
                label="2024"
                variant="outlined"
                id="deliveryEstimates.value_2024"
                value={deliveryEstimates?.value_2024 || ""}
                onChange={(e) => handleDeliveryEstimates("2024", e?.target?.value || "")}
              />
              <MDInput
                type="text"
                label="2025"
                variant="outlined"
                id="deliveryEstimates.value_2025"
                value={deliveryEstimates?.value_2025 || ""}
                onChange={(e) => handleDeliveryEstimates("2025", e?.target?.value || "")}
              />
              <MDInput
                type="text"
                label="2026"
                variant="outlined"
                id="deliveryEstimates.value_2026"
                value={deliveryEstimates?.value_2026 || ""}
                onChange={(e) => handleDeliveryEstimates("2026", e?.target?.value || "")}
              />
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={10} px={1} gap={26} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblActualAnnualDelivery">Actual annual delivery</FormLabel>
              <MDInput
                type="text"
                label="2023"
                variant="outlined"
                id="actulaAnnualDelivery.value_2023"
                value={actulaAnnualDelivery?.value_2023 || ""}
                onChange={(e) => handleActualAnnualDelivery("2023", e?.target?.value || "")}
              />
              <MDInput
                type="text"
                label="2024"
                variant="outlined"
                id="actulaAnnualDelivery.value_2024"
                value={actulaAnnualDelivery?.value_2024 || ""}
                onChange={(e) => handleActualAnnualDelivery("2024", e?.target?.value || "")}
              />
              <MDInput
                type="text"
                label="2025"
                variant="outlined"
                id="actulaAnnualDelivery.value_2025"
                value={actulaAnnualDelivery?.value_2025 || ""}
                onChange={(e) => handleActualAnnualDelivery("2025", e?.target?.value || "")}
              />
              <MDInput
                type="text"
                label="2026"
                variant="outlined"
                id="actulaAnnualDelivery.value_2026"
                value={actulaAnnualDelivery?.value_2026 || ""}
                onChange={(e) => handleActualAnnualDelivery("2026", e?.target?.value || "")}
              />
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} gap={20} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblProjectTaskForce">Project Task Force</FormLabel>
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} gap={20} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblBudgetholder">Budget holder</FormLabel>
              <Select
                sx={{ minWidth: 120 }}
                labelId="demo-simple-select-label"
                id="budjetHolder"
                value={formData?.budjetHolder || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "budjetHolder")}
              >
                <MenuItem value={10}>Budject Holder 1</MenuItem>
                <MenuItem value={20}>Budject Holder 2</MenuItem>
                <MenuItem value={30}>Budject Holder 3</MenuItem>
              </Select>
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} gap={20} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblLTO">LTO</FormLabel>
              <Select
                sx={{ minWidth: 120 }}
                labelId="lblLto"
                id="lto"
                value={formData?.lto || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "lto")}
              >
                <MenuItem value={10}>Budject Holder 1</MenuItem>
                <MenuItem value={20}>Budject Holder 2</MenuItem>
                <MenuItem value={30}>Budject Holder 3</MenuItem>
              </Select>
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} gap={20} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblFLO">FLO</FormLabel>
              <Select
                sx={{ minWidth: 120 }}
                labelId="lblFLO"
                id="flo"
                value={formData?.flo || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "flo")}
              >
                <MenuItem value={10}>Budject Holder 1</MenuItem>
                <MenuItem value={20}>Budject Holder 2</MenuItem>
                <MenuItem value={30}>Budject Holder 3</MenuItem>
              </Select>
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} gap={20} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblProjectManager">Project Manager</FormLabel>
              <Select
                sx={{ minWidth: 120 }}
                labelId="lblProjectManager"
                id="projectManager"
                value={formData?.projectManager || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "projectManager")}
              >
                <MenuItem value={10}>PM 1</MenuItem>
                <MenuItem value={20}>PM 2</MenuItem>
                <MenuItem value={30}>PM 3</MenuItem>
              </Select>
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} gap={20} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblProjectCoordinator">Project Coordinator</FormLabel>
              <Select
                sx={{ minWidth: 120 }}
                labelId="lblProjectCoordinator"
                id="projectCoordinator"
                value={formData?.projectCoordinator || ""}
                onChange={(e) => handleChange(e?.target?.value || "", "projectCoordinator")}
              >
                <MenuItem value={10}>Project Coordinator 1</MenuItem>
                <MenuItem value={20}>Project Coordinator 2</MenuItem>
                <MenuItem value={30}>Project Coordinator 3</MenuItem>
              </Select>
            </MDBox>
          </MDBox>
          <MDBox pt={2} pb={1} px={1} gap={20} display="flex">
            <MDBox gap={2} display="flex">
              <FormLabel id="lblProjectTechnicalStaff">Project technical staff</FormLabel>
              <table>
                {Array.from(Array(projectTechnicalStaffCounter)).map((c, index) => {
                  return (
                    <tr key={c}>
                      <Select
                        sx={{ minWidth: 120, minHeight: 39 }}
                        labelId="demo-simple-select-label"
                        id="projectTechnicalStaff"
                        label="Position"
                        value={projectTechnicalStaffList[index].position || ""}
                        onChange={(e) =>
                          handleProjectTechnicalStaff(e?.target?.value || "", "position", index)
                        }
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                      <MDInput
                        type="text"
                        label="name"
                        variant="outlined"
                        id="projectTechnicalStaffName"
                        value={projectTechnicalStaffList[index].name || ""}
                        onChange={(e) =>
                          handleProjectTechnicalStaff(e?.target?.value || "", "name", index)
                        }
                      />
                      <MDInput
                        type="text"
                        label="Contract Period"
                        variant="outlined"
                        id="projectTechnicalStaffContractPeriod"
                        value={projectTechnicalStaffList[index].contractPeriod || ""}
                        onChange={(e) =>
                          handleProjectTechnicalStaff(
                            e?.target?.value || "",
                            "contractPeriod",
                            index
                          )
                        }
                      />
                      <IconButton color="primary" aria-label="add">
                        <AddIcon color="black" onClick={handleProjectTechnicalStaffClick} />
                      </IconButton>
                    </tr>
                  );
                })}
              </table>
            </MDBox>
          </MDBox>
          <MDBox pt={10} pb={1} px={1} gap={20} display="flex">
            <MDBox gap={2} display="flex">
              <Button onClick={(e) => handleFormSubmit()} variant="contained">
                Save
              </Button>
              <Button variant="contained">Clear</Button>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ProjectInformation;
