/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import StaticCard from "../countryProgram/components/staticCard/CpStatisticCard";

// Data
import reportsLineChartData from "layouts/dashboard/data/FaoReportsLineChartData";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import FAB from "layouts/FAB";
import { Card } from "@mui/material";

import { HrPieChart } from "./components/HrPieChart";
import { HrBarChart } from "./components/HrBarChart";
import DashboarNavBarHr from "examples/Navbars/NavbarHr";
import { navActions } from "components/FaoNavigation/FaoNavigation";
import HrGanttChart from "./components/GanttChart";
import { Chart } from "react-google-charts";
import CountContent from "./components/CountContent";
import districtImg from "assets/images/districts.png";

function HumanResource() {
  const { sales, tasks } = reportsLineChartData;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ganttData, setganttData] = useState([]);
  const [totalContracts, setTotalContracts] = useState(0);

  const axiosPrivate = useAxiosPrivate();

  const [countByData, setCountByuData] = useState([]);
  const [countByProjectData, setCountByProjectData] = useState([]);
  const [nationalInternational, setNationalInternational] = useState([]);
  const [staffDistribution, setStaffDistribution] = useState([]);
  const [appointmentTypeData, setAppointmentTypeData] = useState([]);
  console.log({ countByData });

  useEffect(() => {
    const fetchData = async () => {
      const controller = new AbortController();

      try {
        setLoading(true);
        const hrcontract = `/nshr-listing/get-status`;
        const res = await axiosPrivate.get(hrcontract, {
          headers: {
            signal: controller.signal,
          },
        });
        setTotalContracts(res.data.length);

        const rowss = res?.data?.map((item) => [item[0], new Date(item[1]), new Date(item[2])]);

        setData(rowss && rowss);

        const columns = [
          { type: "string", id: "name" },
          { type: "date", id: "Start" },
          { type: "date", id: "End" },
        ];

        const dataValues = [columns, ...rowss];
        setganttData(dataValues);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchCountByTypeData = async () => {
      const controller = new AbortController();

      try {
        setLoading(true);
        const hrcontract = `recruitment/count-by-type`;
        const res = await axiosPrivate.get(hrcontract, {
          headers: {
            signal: controller.signal,
          },
        });

        //setTotalContracts(res.data.length);
        const formattedData = res?.data?.map((item) => [item.typeOfRequest, item.count]);
        const newdata = [["Contract type", "Count"], ...formattedData];
        if (newdata?.length > 0) {
          setCountByuData(newdata);
        }

        console.log({ newdata });

        //const rowss = res?.data?.map((item) => [item[0], new Date(item[1]), new Date(item[2])]);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCountByTypeData();
  }, []);

  useEffect(() => {
    const fetchCountByProject = async () => {
      const controller = new AbortController();

      try {
        setLoading(true);
        const hrcontract = `recruitment/count-by-project`;
        const res = await axiosPrivate.get(hrcontract, {
          headers: {
            signal: controller.signal,
          },
        });

        //setTotalContracts(res.data.length);
        const formattedData = res?.data?.map((item) => [item.projectCode, item.count]);
        const newdata = [["Project Code", "Count"], ...formattedData];
        if (newdata?.length > 0) {
          setCountByProjectData(newdata);
        }

        console.log({ newdata });

        //const rowss = res?.data?.map((item) => [item[0], new Date(item[1]), new Date(item[2])]);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCountByProject();
  }, []);

  useEffect(() => {
    const fetchCountByProject = async () => {
      const controller = new AbortController();

      try {
        setLoading(true);
        const hrcontract = `staff/countByNationalInternational`;
        const res = await axiosPrivate.get(hrcontract, {
          headers: {
            signal: controller.signal,
          },
        });

        //setTotalContracts(res.data.length);
        const formattedData = res?.data?.map((item) => [item.name, item.count]);

        const newdata = [["Personel By Geography", "Count"], ...formattedData];
        if (newdata?.length > 0) {
          setNationalInternational(newdata);
        }

        console.log({ newdata });

        //const rowss = res?.data?.map((item) => [item[0], new Date(item[1]), new Date(item[2])]);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCountByProject();
  }, []);

  useEffect(() => {
    const fetchStaffDistribution = async () => {
      const controller = new AbortController();

      try {
        setLoading(true);
        const hrcontract = `staff/countByOfficeType`;
        const res = await axiosPrivate.get(hrcontract, {
          headers: {
            signal: controller.signal,
          },
        });

        //setTotalContracts(res.data.length);
        const formattedData = res?.data?.map((item) => [item.name, item.count]);
        const newdata = [["Staff Distribution", "Count"], ...formattedData];
        if (newdata?.length > 0) {
          setStaffDistribution(newdata);
        }

        console.log({ newdata });

        //const rowss = res?.data?.map((item) => [item[0], new Date(item[1]), new Date(item[2])]);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchStaffDistribution();
  }, []);

  useEffect(() => {
    const fetchAppointmentTypeData = async () => {
      const controller = new AbortController();

      try {
        setLoading(true);
        const hrcontract = `staff/countByAppointmentType`;
        const res = await axiosPrivate.get(hrcontract, {
          headers: {
            signal: controller.signal,
          },
        });

        //setTotalContracts(res.data.length);
        const formattedData = res?.data?.map((item) => [item.name, item.count]);
        const newdata = [["Staff Distribution", "Count"], ...formattedData];
        if (newdata?.length > 0) {
          setAppointmentTypeData(newdata);
        }

        console.log({ newdata });

        //const rowss = res?.data?.map((item) => [item[0], new Date(item[1]), new Date(item[2])]);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAppointmentTypeData();
  }, []);

  const optionsBar = {
    title: " New Recruitment by Type of Request",
    chartArea: { width: "70%" },
    hAxis: {
      title: "Count",
      minValue: 0,
    },
    vAxis: {
      title: "Type Of Request",
    },
  };

  const optionsBarTwo = {
    title: "New Recruitment by Project",
    chartArea: { width: "50%" },
    hAxis: {
      title: "Count",
      minValue: 0,
    },
    vAxis: {
      title: "Project",
    },
  };

  const optionsNationalInternalNational = {
    title: "Count of National / International",
    chartArea: { width: "50%" },
    hAxis: {
      title: "Count",
      minValue: 0,
    },
    vAxis: {
      title: "National/International",
    },
  };

  const optionsStaffDistribusion = {
    title: "Staff Distribution",
    chartArea: { width: "50%" },
    hAxis: {
      title: "Count",
      minValue: 0,
    },
    vAxis: {
      title: "Project/Country Office",
    },
  };

  const optionsAppointmentType = {
    title: "Appointment Type",
    chartArea: { width: "50%" },
    hAxis: {
      title: "Count",
      minValue: 0,
    },
    vAxis: {
      title: "Appointment Type",
    },
  };

  return (
    <PageLayout>
      <DashboarNavBarHr />
      <Card>
        <MDBox py={1} px={2} display="flex" justifyContent="flex-end" height={50}></MDBox>
      </Card>
      <MDBox py={1} mt={2} px={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <StaticCard
                color="warning"
                icon="wallet"
                title="active contract"
                count={totalContracts}
                loading={loading}
                fromYear="2022"
                toYear="2023"
                percentage={false}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <CountContent
                icon={districtImg}
                title="Staff Distributions"
                // count={
                //   data.districtDistribution ? Object.keys(data.districtDistribution).length : 0
                // }
                disable="true"
                loading={loading}
                linkTo="/project-list-user"
              />
            </MDBox>
          </Grid>
        </Grid>
        <br />

        <Grid container spacing={1}>
          <Grid item xs={6} md={6} lg={6}>
            <Card sx={{ padding: 2 }}>
              {nationalInternational?.length > 0 && (
                <>
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="500px"
                    data={nationalInternational ? nationalInternational : []}
                    options={optionsNationalInternalNational}
                  />
                </>
              )}
            </Card>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Card sx={{ padding: 2 }}>
              {staffDistribution?.length > 0 && (
                <>
                  <Chart
                    chartType="BarChart"
                    width="100%"
                    height="500px"
                    data={staffDistribution ? staffDistribution : []}
                    options={optionsStaffDistribusion}
                  />
                </>
              )}
            </Card>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Card sx={{ padding: 2 }}>
              {appointmentTypeData?.length > 0 && (
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height="500px"
                  data={appointmentTypeData ? appointmentTypeData : []}
                  options={optionsAppointmentType}
                />
              )}
            </Card>
          </Grid>

          <Grid item xs={6} md={6} lg={6}>
            <Card sx={{ padding: 2 }}>
              {countByData?.length > 0 && (
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height="500px"
                  data={countByData ? countByData : []}
                  options={optionsBar}
                />
              )}
            </Card>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <Card sx={{ padding: 2 }}>
                  {countByProjectData?.length > 0 && (
                    <>
                      <Chart
                        chartType="BarChart"
                        width="100%"
                        height="500px"
                        data={countByProjectData ? countByProjectData : []}
                        options={optionsBarTwo}
                      />
                    </>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6} md={6} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <Card sx={{ padding: 2 }}>
                  <HrPieChart />
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Card sx={{ padding: 2 }}>
                  <HrGanttChart hrArray={ganttData} loading={loading} />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>

      <FAB actions={navActions()} />
    </PageLayout>
  );
}

export default HumanResource;
