/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Images
import itemIcon from "assets/images/small-logos/items.jpg";

export default function data(values, origin) {
  const Company = ({ image, name, amount }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
      &nbsp; - &nbsp;
      <MDTypography variant="text" size="lg" ml={1} fontWeight="medium">
        {amount}
      </MDTypography>
    </MDBox>
  );
  //assign the measurement unit
  const markAssign = (key, value) => {
    if (
      key == "Grinders" ||
      key == "Poly Tunnels" ||
      key == "IPNM Training" ||
      key == "Hi-tech Agriculture Package" ||
      key == "Rain Shelters" ||
      key == "Seed Paddy Training"
    ) {
      return (
        <Company image={itemIcon} name={key} amount={value?.toLocaleString("en-US") + " NOS"} />
      );
    } else if (key == "Paddy Seed" || key == "TSP fertilizer" || key == "Urea fertilizer") {
      return (
        <Company image={itemIcon} name={key} amount={value?.toLocaleString("en-US") + " MT"} />
      );
    } else if (value === "" || value === undefined || value === null) {
      return <Company image={itemIcon} name={key} amount={value?.toLocaleString("en-US")} />;
    } else if (key === "Supplementry Food") {
      return (
        <Company image={itemIcon} name={key} amount={value?.toLocaleString("en-US") + " KG"} />
      );
    } else {
      return <Company image={itemIcon} name={key} amount={value?.toLocaleString("en-US")} />;
    }
  };

  return {
    columns: [{ Header: "description", accessor: "description", width: "100%", align: "left" }],

    rows: values
      ? Object.entries(values).map(([key, value]) => ({
          description: markAssign(key, value),
        }))
      : [],
  };
}
