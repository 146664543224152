/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import PropTypes, { any } from "prop-types";

// Data

import dataWithoutDelivery from "./withoutDeliveryData";
import FaoDataTable from "examples/Tables/FaoDataTable";
import data from "layouts/DigitalInitiative/data";
import HrDataTable from "./FaoDataTable";

function UserListHr({
  countryData,
  showDelivery = true,
  onDonorRowClick = () => {},
  clickedRowIndex = null,
  clearFilterData = () => {},
}) {
  console.log("newcd", countryData);
  const { columns, rows } = dataWithoutDelivery(countryData);
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  return (
    <Card style={{ height: "auto", overflow: "auto" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <MDTypography variant="h6" gutterBottom>
          User List
        </MDTypography>
      </MDBox>
      <MDBox>
        <HrDataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
          onRowClick={onDonorRowClick}
          clickedRowIndex={clickedRowIndex}
        />
      </MDBox>
    </Card>
  );
}

UserListHr.propTypes = {
  countryData: PropTypes.any,
  showDelivery: PropTypes.bool,
  onDonorRowClick: any,
  clickedRowIndex: any,
  clearFilterData: any,
};

export default UserListHr;
