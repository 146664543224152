const breakpoints = [640, 384, 256, 128];

import img1 from "assets/images/projImg/1.png";
import img2 from "assets/images/projImg/2.png";
import img3 from "assets/images/projImg/3.png";
import img4 from "assets/images/projImg/4.png";
import img5 from "assets/images/projImg/5.png";
import img6 from "assets/images/projImg/6.png";
import img7 from "assets/images/projImg/7.png";
import img8 from "assets/images/projImg/8.png";
import img9 from "assets/images/projImg/9.png";
import img10 from "assets/images/projImg/10.png";
import img11 from "assets/images/projImg/11.png";
import img12 from "assets/images/projImg/12.png";
import img13 from "assets/images/projImg/13.png";
import img14 from "assets/images/projImg/14.png";
import img15 from "assets/images/projImg/15.png";
import img16 from "assets/images/projImg/16.png";
import img17 from "assets/images/projImg/17.png";
import img18 from "assets/images/projImg/18.png";
import img19 from "assets/images/projImg/19.png";
import img20 from "assets/images/projImg/20.png";
import img21 from "assets/images/projImg/21.png";
import img22 from "assets/images/projImg/22.png";
import img23 from "assets/images/projImg/23.png";

const unsplashPhotos = [
  {
    source: img1,
    width: 720,
    height: 512,
  },
  {
    source: img2,
    width: 720,
    height: 512,
  },
  {
    source: img3,
    width: 720,
    height: 512,
  },
  {
    source: img4,
    width: 720,
    height: 512,
  },
  {
    source: img5,
    width: 720,
    height: 512,
  },
  {
    source: img6,
    width: 720,
    height: 512,
  },
  {
    source: img7,
    width: 720,
    height: 512,
  },
  {
    source: img8,
    width: 720,
    height: 512,
  },
  {
    source: img9,
    width: 720,
    height: 512,
  },
  {
    source: img10,
    width: 720,
    height: 512,
  },
  {
    source: img11,
    width: 720,
    height: 512,
  },
  {
    source: img12,
    width: 720,
    height: 512,
  },
  {
    source: img13,
    width: 720,
    height: 512,
  },
  {
    source: img14,
    width: 720,
    height: 512,
  },
  {
    source: img15,
    width: 720,
    height: 512,
  },
  {
    source: img16,
    width: 720,
    height: 512,
  },
  {
    source: img17,
    width: 720,
    height: 512,
  },
  {
    source: img18,
    width: 720,
    height: 512,
  },
  {
    source: img19,
    width: 720,
    height: 512,
  },
  {
    source: img20,
    width: 720,
    height: 512,
  },
  {
    source: img21,
    width: 720,
    height: 512,
  },
  {
    source: img22,
    width: 720,
    height: 512,
  },
  {
    source: img23,
    width: 720,
    height: 512,
  },
];

export const slides = unsplashPhotos.map((photo) => {
  const width = photo.width * 4;
  const height = photo.height * 4;
  return {
    src: photo.source,
    width,
    height,
    srcSet: breakpoints.map((breakpoint) => {
      const breakpointHeight = Math.round((height / width) * breakpoint);
      return {
        src: photo.source,
        width: breakpoint,
        height: breakpointHeight,
      };
    }),
  };
});

export const advancedSlides = [
  { ...slides[0], title: "Puppy in sunglasses", description: "Mollie Sivaram" },
  {
    ...slides[1],
    title: "Miami Beach",
    description: "Clark Van Der Beken\n\nSouth Beach, Miami Beach, Florida, United States",
  },
  {
    ...slides[2],
    title: "Flamingo",
    description: "Vicko Mozara\n\nVeliki zali, Dubravica, Croatia",
  },
  {
    type: "video",
    title: "Big Buck Bunny",
    description: "The Peach Open Movie Project\n\nBlender Institute, Netherlands",
    width: 1280,
    height: 720,
    poster:
      "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg",
    sources: [
      {
        src: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        type: "video/mp4",
      },
    ],
  },
  {
    ...slides[3],
    title: "Starfish on a sand beach",
    description: "Pedro Lastra\n\nKey West, Florida, United States",
  },
  {
    ...slides[6],
    title: "The last night of a two week stay on the North Shore of Oahu, Hawaii",
    description: "Sean Oulashin\n\nNorth Shore, Waialua, Hawaii, United States",
  },
  {
    ...slides[7],
    title: "Sunset on Kauai",
    description: "Cristofer Maximilian\n\nKauai, Hawaii, United States",
  },
  {
    ...slides[9],
    title: "RayBan sunglasses",
    description: "Ethan Robertson\n\nSanta Monica, California, United States",
  },
  {
    ...slides[11],
    title: "Find the time",
    description: "Alex Perez\n\nNaples, Florida, United States",
  },
];

export default slides;
