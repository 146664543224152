/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import Flag from "react-world-flags";

export default function datafour(countryData) {
  const Author = ({ countryCode, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <Flag code={countryCode} height="30" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>

        <Tooltip title={email} placement="right-start">
          <MDTypography variant="text">{limit(email, 100)}</MDTypography>
        </Tooltip>
      </MDBox>
    </MDBox>
  );

  function limit(string = "", limit = 0) {
    if (string.length < limit) return string;

    return string.substring(0, limit - 4) + "...";
  }

  function getDeliveryStatus(status) {
    switch (status) {
      case "OC":
        return "Op Closed";
      case "FC":
        return "Fi Closed";
      case "AC":
        return "Ac Completed";
      case "OA":
        return "Op Active";
    }
  }

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const rowData = countryData.map((rows) => ({
    project: rows[0],
    title: rows[1],
    softCommitment: rows[3],
    hardCommitment: rows[4],
    actual: rows[6],
    deliveryPercentage: rows[5],
    Balance: rows[7],
  }));
  console.log("rowData", rowData);
  return {
    columns: [
      { Header: "Project", accessor: "project", width: "15%", align: "left" },
      { Header: "Title", accessor: "title", align: "left" },
      // { Header: "Sum of Soft Commitment", accessor: "softCommitment", align: "right" },

      { Header: "Commitments & Actuals", accessor: "deliveryPercentage", align: "right" },
      { Header: "Total Budget", accessor: "actual", align: "right" },
      { Header: "Balance", accessor: "Balance", align: "right" },
      { Header: "Hard Commitment ", accessor: "hardCommitment", align: "right" },
    ],

    rows:
      rowData &&
      rowData.map((row) => {
        return {
          project: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.project}
            </MDTypography>
          ),
          title: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.title}
            </MDTypography>
          ),
          // softCommitment: (
          //   <MDTypography variant="caption" color="text" fontWeight="medium">
          //     {row.softCommitment}
          //   </MDTypography>
          // ),
          softCommitment: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.softCommitment ? USDollar.format(row.softCommitment) : "$0.00"}
            </MDTypography>
          ),
          hardCommitment: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.hardCommitment ? USDollar.format(row.hardCommitment) : "$0.00"}
            </MDTypography>
          ),
          actual: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.actual ? USDollar.format(row.actual) : "$0.00"}
            </MDTypography>
          ),
          deliveryPercentage: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.deliveryPercentage ? USDollar.format(row.deliveryPercentage) : "$0.00"}
            </MDTypography>
          ),
          Balance: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.Balance ? USDollar.format(row.Balance) : "$0.00"}
            </MDTypography>
          ),
        };
      }),
  };
}
