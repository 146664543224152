import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import React, { useEffect, useState } from "react";
import { OrganizationChart } from "primereact/organizationchart";

import "./framework.css";
import useAxiosPrivate from "hooks/useAxiosPrivate";

import bpro from "../../../../assets/images/framework/bp.svg";
import blife from "../../../../assets/images/framework/bl.svg";
import benv from "../../../../assets/images/framework/be.svg";
import bnut from "../../../../assets/images/framework/bn.svg";

import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import GanttChart from "layouts/OngoingProjectProfile/components/ganttChart";

const CountryFramework = ({ id }) => {
  const [selection, setSelection] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    const fetchFrameworkData = async () => {
      const controller = new AbortController();
      try {
        setLoading(true);
        const response = await axiosPrivate.get(`country/project/${id}/result-framework`, {
          headers: {
            signal: controller.signal,
          },
        });
        setData(response.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchFrameworkData();
  }, []);

  const nodeTemplate = (node) => {
    var image = null;

    if (node.type === "FOUR_BETTER") {
      var fourBetters = node?.label?.split(",");

      let images = [];
      for (let i = 0; i < fourBetters.length; i++) {
        var fourBetter = fourBetters[i];
        if (fourBetter === "BP") {
          images.push(bpro);
        } else if (fourBetter === "BL") {
          images.push(blife);
        } else if (fourBetter === "BE") {
          images.push(benv);
        } else if (fourBetter === "BN") {
          images.push(bnut);
        }
      }

      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  //alt={node.data.avatar}
                  src={image}
                  onError={(e) =>
                    (e.target.src =
                      "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                  }
                  style={{ width: "100px" }}
                />
              </div>
            ))}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box p={2}>
              {node?.blDataList[0]?.ppaCode}: {node?.blDataList[0]?.organizationResult} (
              {node?.blDataList[0]?.percentage}%)
            </Box>
          </Grid>
          {node.blDataList[1] ? (
            <Grid item xs={12} sm={12}>
              <Box p={2}>
                {node?.blDataList[1]?.ppaCode}: {node?.blDataList[1]?.organizationResult} (
                {node?.blDataList[1]?.percentage}%)
              </Box>
            </Grid>
          ) : null}
        </Grid>
      );
    }
    if (node.type === "BL") {
      return (
        <div>
          <div className="node-header">
            {node?.blData?.ppaCode + " (" + node?.blData?.percentage + "%)"}
          </div>
          <div className="node-content">
            <div>{node?.blData?.organizationResult}</div>
          </div>
        </div>
      );
    }

    if (node.type === "IMPACT") {
      return (
        <div>
          <div className="node-header">{node?.type}</div>
          <div className="node-content">
            <div>{node?.data?.results}</div>
            <div style={{ color: "red" }}>Indicator: {node?.data?.indicator}</div>
          </div>
        </div>
      );
    }

    if (node.type === "OUTCOME") {
      return (
        <div>
          <div className="node-header">{node?.type}</div>
          <div className="node-content">
            <div>{node?.data?.results}</div>
            <div>
              Indicatores:{" "}
              {node?.data.indicators.map((item, index) => (
                <div style={{ color: "red" }} key={index}>
                  <p>{item.indicator}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    if (node.type === "OUTPUT") {
      return (
        <div>
          <div className="node-header">{node?.type}</div>
          <div className="node-content">
            <div>{node?.data?.results}</div>
            <div>
              Indicatores:{" "}
              {node?.data.indicators.map((item, index) => (
                <div style={{ color: "red" }} key={index}>
                  <p>{item.indicator}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }

    if (node.type === "INDICATOR") {
      return (
        <div>
          <div className="node-header">{node?.type}</div>
          <div className="node-content">
            <div>{node?.data?.results}</div>
          </div>
        </div>
      );
    }

    return node.type;
  };

  return (
    <div className="organizationchart-demo">
      <div className="card">
        <h5>Result Framework</h5>
        {!loading && data && (
          <OrganizationChart
            value={data}
            nodeTemplate={nodeTemplate}
            selection={selection}
            selectionMode="multiple"
            onSelectionChange={(event) => setSelection(event.data)}
            className="company"
          ></OrganizationChart>
        )}

        <Grid item xs={12} xl={12}>
          <GanttChart id={id} />
        </Grid>
      </div>
    </div>
  );
};

CountryFramework.propTypes = {
  id: PropTypes.any,
};

export default CountryFramework;
