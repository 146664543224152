import React from "react";

import introImg from "../../assets/images/intro.jpg";
import "./FullPageImage.css";
import MDButton from "components/MDButton";
import { Grid } from "@mui/material";
import Basic from "layouts/authentication/sign-in";

export default function IntroPage() {
  return (
    <div className="full-page-image">
      <div className="full-page-image">
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            md={9}
            sx={{
              backgroundImage: `url(${introImg})`,
              backgroundSize: "cover",
              width: "100vw",
              height: "100vh",
            }}
          ></Grid>

          <Grid item md={3} px={2} pl={4}>
            <Basic />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
