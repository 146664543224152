/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";

import authorsTableData from "./ProjectTable/data/authorsTableData";
import DataTable from "examples/Tables/DataTable";
import { Grid } from "@mui/material";

function ProfilesList({ title, profiles, shadow }) {
  const INPUT_DISTRIBUTION = "Input Distribution";
  const CASH_DISTRIBUTION = "Cash Distribution";
  const CAPCITY_BUILDING = "Capacity Building";
  const NUTRITION_ASSISTANCE = "Nutrition Assistance";
  const OTHER = "OTHER";

  const [districtData, setDistrictData] = useState({});
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const groupedData = profiles?.reduce((acc, curr) => {
      const { district, activity, beneficiaries } = curr;

      const activityGroup = getCategoryGroup(activity);

      if (!acc[district]) {
        acc[district] = {};
      }

      if (!acc[district][activityGroup]) {
        acc[district][activityGroup] = 0;
      }

      acc[district][activityGroup] += beneficiaries;

      return acc;
    }, {});

    setDistrictData(groupedData);
    const { columns1, rows1 } = authorsTableData(groupedData);
    setColumns(columns1);
    setRows(rows1);
  }, [profiles]);

  function getCategoryGroup(activity) {
    switch (activity) {
      case "Urea fertilizer Distribution":
        return INPUT_DISTRIBUTION;
      case "TSP Fertilizer Distribution":
        return INPUT_DISTRIBUTION;
      case "Paddy Seed Distribution":
        return INPUT_DISTRIBUTION;
      case "Agriculture input support":
        return INPUT_DISTRIBUTION;
      case "Machinery and infrastructure":
        return INPUT_DISTRIBUTION;
      case "Cash Grants":
        return CASH_DISTRIBUTION;
      case "Cash Asistance":
        return CASH_DISTRIBUTION;
      case "Cash Grants for Schools":
        return CASH_DISTRIBUTION;
      case "School Teacher Trainings":
        return CAPCITY_BUILDING;
      case "IPNM Training":
        return CAPCITY_BUILDING;
      case "Training of Trainers (ToTs)":
        return CAPCITY_BUILDING;
      case "Farmer Trainings through FFS":
        return CAPCITY_BUILDING;
      case "Capacity building":
        return CAPCITY_BUILDING;
      case "Supplementry Food":
        return NUTRITION_ASSISTANCE;

      default:
        return OTHER;
    }
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(district) {
    return {
      sx: {
        bgcolor: stringToColor(district),
      },
      children: `${district.split(" ")[0][0]}${district.split(" ")[1][0]}`,
    };
  }

  const renderProfile = profiles?.map(function (district, index) {
    return <MDBox pt={6} pb={3} key={index}></MDBox>;
  });

  return (
    <Card
      sx={{ height: "100%", boxShadow: !shadow && "none", width: "100%", background: "#D9E3F0" }}
    >
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" fontSize="20px">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.any,
  shadow: PropTypes.bool,
};

export default ProfilesList;
