/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Overview page components

import HeaderWithoutAvatar from "./components/HeaderWithoutAvatar";
// Images
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import "react-image-gallery/styles/css/image-gallery.css";
import CountContent from "./components/CountContent";

import districtImg from "assets/images/navigation/finance.png";

import be from "assets/images/bettericon/be.svg";
import bl from "assets/images/bettericon/bl.svg";
import bn from "assets/images/bettericon/bn.svg";
import bp from "assets/images/bettericon/bp.svg";
import Chart from "react-google-charts";
import NewFaoFinanceList from "layouts/dashboard/components/FinanceList/newIndex";
import { axiosPrivate } from "api/axios";

function DonorProfile() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [donorId, setDonorId] = useState(id);
  const [chartData, setChartData] = useState([]);
  console.log({ chartData });
  const [totalBudget, setTotalBudget] = useState(0);
  console.log({ totalBudget });
  const [totalDelivery, setTotalDelivery] = useState(0);
  const [projectSymbol, setProjectSymbol] = useState("");
  const [projectTitle, setProjectTitle] = useState("");
  const [donorName, setDonorName] = useState("");
  console.log({ loading });

  const [countryDataList, setCountryDataList] = useState([]);

  useEffect(() => {
    setDonorName(donorId);
  }, []);

  useEffect(() => {
    console.log("inside donor profile useEffect");
    const fetchProjectData = async () => {
      console.log("inside fetchProjectData");
      const controller = new AbortController();
      setLoading(true);
      try {
        let emUrl = `/project-dashboard-report/${donorId}`;

        const response = await axiosPrivate.get(emUrl, {
          headers: {
            signal: controller.signal,
          },
        });

        console.log("dres", response);

        const data = response?.data;
        const chartData = [["Year", "Budget", "Delivery"]];
        data.funding.forEach((entry) => {
          chartData.push([entry[0].toString(), entry[1], entry[2]]);
        });
        setChartData(chartData);
        setTotalBudget(data?.totalBudget);
        setTotalDelivery(data?.totalDelivery);
        setProjectSymbol(data?.projectSymbol);
        setProjectTitle(data?.projectTitle);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProjectData();
  }, []);

  useEffect(() => {
    console.log("inside donor profile useEffect");
    const fetchDonorData = async () => {
      console.log("inside fetchProjectData");
      const controller = new AbortController();
      setLoading(true);
      try {
        let emUrl = `/entity-summaries/${donorId}`;

        const response = await axiosPrivate.get(emUrl, {
          headers: {
            signal: controller.signal,
          },
        });
        setCountryDataList(response.data);

        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDonorData();
  }, []);
  console.log("countryDataList", countryDataList);
  const [dataFilter, setDataFilter] = useState(null);

  const [districtDistribution, setDistrictDistribution] = useState([]);

  const [clickedRowIndex, setClickedRowIndex] = useState(null);

  const [betterInfo, setBetterInfo] = useState({
    image: "",
    entityNumber: "",
    code: "",
    description: "",
    ppaList: "",
  });

  const [filteredData, setFilteredData] = useState(null);

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function getFourBetterIcon(strategicFrameworkStrategicObjective, ppaInfoList, entityNumber) {
    switch (strategicFrameworkStrategicObjective) {
      case "BE":
        return {
          image: be,
          entityNumber: entityNumber,
          code: "Better Environment",
          description:
            "Protect, restore and promote sustainable use of terrestrial and marine ecosystems and combat climate change (reduce, reuse, recycle, residual management) through more efficient, inclusive, resilient and sustainable agri-food systems",
          ppaList: ppaInfoList,
        };
      case "BL":
        return {
          image: bl,
          code: "Better Life",
          entityNumber: entityNumber,
          description:
            "Promote inclusive economic growth by reducing inequalities (urban/rural areas, rich/poor countries, men/women)",
          ppaList: ppaInfoList,
        };
      case "BN":
        return {
          image: bn,
          code: "Better Nutrition",
          entityNumber: entityNumber,
          description:
            "End hunger, achieve food security and improved nutrition in all its forms, including promoting nutritious food and increasing access to healthy diets",
          ppaList: ppaInfoList,
        };
      case "BP":
        return {
          image: bp,
          code: "Better Production",
          entityNumber: entityNumber,
          description:
            "Ensure sustainable consumption and production patterns, through efficient and inclusive food and agriculture supply chains at local, regional and global level, ensuring resilient and sustainable agri-food systems in a changing climate and environment",
          ppaList: ppaInfoList,
        };
      default:
        return {
          image: bp,
          code: "Better Production",
          entityNumber: entityNumber,
          description:
            "Ensure sustainable consumption and production patterns, through efficient and inclusive food and agriculture supply chains at local, regional and global level, ensuring resilient and sustainable agri-food systems in a changing climate and environment",
          ppaList: ppaInfoList,
        };
    }
  }

  // const chartData = [
  //   ["Year", "Budget", "Delivery"],
  //   ["2014", 1000, 980],
  //   ["2015", 1170, 1160],
  //   ["2016", 3160, 3160],
  //   ["2017", 1030, 1030],
  //   ["2018", 2011, 2000],
  //   ["2019", 3030, 3000],
  //   ["2020", 1900, 1900],
  //   ["2021", 1080, 1060],
  //   ["2022", 3659, 3590],
  //   ["2023", 2587, 2500],
  //   ["2024", 1589, 1589],
  // ];

  const options = {
    chart: {
      title: "Yearly Fundings",
      subtitle: "Swedish: 2014-2024",
    },
  };

  return (
    <PageLayout>
      <MDBox mb={2} />
      <HeaderWithoutAvatar
        projectName={donorName}
        projectTitle={projectTitle}
        projectSymbol={projectSymbol}
      >
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1} justifyContent={"center"}>
            <Grid item xs={12} md={6} xl={5}>
              <MDBox mb={1.5}>
                {loading === false && (
                  <>
                    <CountContent
                      icon={districtImg}
                      title="Total Budget"
                      count={totalBudget ? USDollar.format(`${totalBudget}`) : 0}
                      //loading={loading}
                    />
                  </>
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} xl={5}>
              <MDBox mb={1.5}>
                <CountContent
                  icon={districtImg}
                  title="Total Delivery"
                  count={totalDelivery ? USDollar.format(`${totalDelivery}`) : 0}
                  //loading={loading}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Chart chartType="Bar" width="100%" height="400px" data={chartData} options={options} />
          </Grid>
          <Grid item xs={12} md={12}>
            <NewFaoFinanceList
              countryData={countryDataList}
              showDelivery={false}
              onDonorRowClick={() => {}}
              clickedRowIndex={clickedRowIndex}
              clearFilterData={() => {}}
            />
          </Grid>
        </Grid>
      </HeaderWithoutAvatar>
    </PageLayout>
  );
}

DonorProfile.propTypes = {
  props: PropTypes.string,
};

export default DonorProfile;
