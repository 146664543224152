/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardNavbar from "examples/Navbars/CountryProgramNavbar";
import ReportsLineChart from "examples/Charts/LineCharts/FaoReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/CpStatisticCard";

// Data
import reportsLineChartData from "layouts/dashboard/data/FaoReportsLineChartData";

// Dashboard components
import PageLayout from "examples/LayoutContainers/PageLayout";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Box, Card, FormControlLabel, Slider, Switch, Typography } from "@mui/material";
import MDButton from "components/MDButton";

import { useNavigate } from "react-router-dom";
import FaoDonarContribution from "layouts/dashboard/components/FaoCountryList";
import DeliverableTypes from "./components/deliverableTypes";
import StaticCard from "./components/staticCard/CpStatisticCard";

import FAB from "layouts/FAB";
import OZDivider from "components/OZDivider";
import { navActions } from "components/FaoNavigation/FaoNavigation";
import CountContent from "layouts/emergency/components/CountContent";
import countImg1 from "assets/images/contImg1.jpg";

function valuetext(value) {
  return `${value}`;
}

function Emergency() {
  const { sales, tasks } = reportsLineChartData;

  const [data, setData] = useState([]);
  console.log("newd", data);
  const [loading, setLoading] = useState(true);
  const [mapData, setMapData] = useState([]);
  const [projects, setProjects] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [countryDataList, setCountryDataList] = useState([]);

  const [checkedOa, setCheckedOa] = useState(false);
  const [checkedAc, setCheckedAc] = useState(false);
  const [checkedFc, setCheckedFc] = useState(false);
  const [checkedPipelined, setCheckedPipelined] = useState(false);
  const [checkedOc, setCheckedOc] = useState(false);

  const [checkedTcp, setCheckedTcp] = useState(false);
  const [checkedEmergency, setCheckedEmergency] = useState(false);
  const [checkedOtherDevProjects, setCheckedOtherDevProjects] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const [value, setValue] = useState([1987, 2024]);

  const [startDate, setStartDate] = useState(1987);
  const [endDate, setEndDate] = useState(2024);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStartDate(newValue[0]);
    setEndDate(newValue[1]);
    filterData(newValue[0], newValue[1]);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const statusFilter = localStorage.getItem("statusFilter");
    const typeFilter = localStorage.getItem("typeFilter");

    if (statusFilter) {
      if (statusFilter.includes("OA")) {
        setCheckedOa(true);
      }
      if (statusFilter.includes("AC")) {
        setCheckedAc(true);
      }
      if (statusFilter.includes("FC")) {
        setCheckedFc(true);
      }
      if (statusFilter.includes("P1,P2,P3")) {
        setCheckedPipelined(true);
      }
      if (statusFilter.includes("OC")) {
        setCheckedOc(true);
      }
    }

    if (typeFilter) {
      if (typeFilter.includes("FAO")) {
        setCheckedTcp(true);
      }
      if (typeFilter.includes("EMERGENCY")) {
        setCheckedEmergency(true);
      }
      if (typeFilter.includes("OTHER")) {
        setCheckedOtherDevProjects(true);
      }
    }

    filterData(null, null);
  }, []);

  const createDistrictData = (data) => {
    // Extracting years and values
    const districts = Object.keys(data);

    const filledData = [];
    districts.forEach((district) => {
      filledData.push(data[district]);
    });

    const budgets = {
      labels: districts,
      datasets: { label: "District", data: filledData },
    };
    return budgets;
  };

  const getMapData = (data) => {
    // Extracting years and values
    const years = Object.keys(data);
    const values = Object.values(data);

    // Finding the earliest and latest years
    const earliestYear = Math.min(...years);
    const latestYear = Math.max(...years);

    // Filling in missing years with zeros
    const filledData = [];
    for (let year = earliestYear; year <= latestYear; year++) {
      // const dataSet = { year: year.toString(), budgetVal: data[year.toString()] };
      const value = data[year.toString()] || 0;
      filledData.push(value);

      // const dataSet = { year: year.toString(), budgetVal: data[year.toString()] };
      // filledData.push(dataSet);
    }

    // Creating the desired format for the line chart
    const budgets = {
      labels: Array.from({ length: latestYear - earliestYear + 1 }, (_, i) =>
        (earliestYear + i).toString()
      ),
      datasets: { label: "Year", data: filledData },
    };
    return budgets;
  };

  function formatNumber(num, precision = 2) {
    const map = [
      { suffix: "T", threshold: 1e12 },
      { suffix: "B", threshold: 1e9 },
      { suffix: "M", threshold: 1e6 },
      { suffix: "K", threshold: 1e3 },
      { suffix: "", threshold: 1 },
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
      return formatted;
    }

    return num;
  }

  useEffect(() => {
    let filter = "";
    if (checkedOa) {
      filter += "OA,";
    }
    if (checkedAc) {
      filter += "AC,";
    }
    if (checkedFc) {
      filter += "FC,";
    }
    if (checkedPipelined) {
      filter += "P1,P2,P3,";
    }
    if (checkedOc) {
      filter += "OC,";
    }
    filter = filter.endsWith(",") ? filter.slice(0, -1) : filter;
    //setStatusFilter(filter);
    localStorage.setItem("statusFilter", filter);

    let typeFilter = "";
    if (checkedTcp) {
      typeFilter += "FAO,";
    }
    if (checkedEmergency) {
      typeFilter += "EMERGENCY,";
    }
    if (checkedOtherDevProjects) {
      typeFilter += "OTHER,";
    }

    typeFilter = typeFilter.endsWith(",") ? typeFilter.slice(0, -1) : typeFilter;
    //setTypeFilter(typeFilter);
    localStorage.setItem("typeFilter", typeFilter);
  }, [
    checkedOa,
    checkedAc,
    checkedFc,
    checkedPipelined,
    checkedTcp,
    checkedEmergency,
    checkedOtherDevProjects,
  ]);

  const ClearFilterData = () => {
    localStorage.setItem("statusFilter", "");
    localStorage.setItem("typeFilter", "");

    setCheckedOa(false);

    setCheckedAc(false);

    setCheckedFc(false);

    setCheckedPipelined(false);

    setCheckedOc(false);

    setCheckedTcp(false);

    setCheckedEmergency(false);

    setCheckedOtherDevProjects(false);

    setStartDate(1987);
    setEndDate(2024);

    setValue([1987, 2024]);

    filterData(null, null);
  };

  const onfilterData = () => {
    setStartDate(1987);
    setEndDate(2024);
    setValue([1987, 2024]);
    filterData(null, null);
  };

  const filterData = async (sDate, eDate) => {
    const controller = new AbortController();
    try {
      setLoading(true);
      const statusFilter = localStorage.getItem("statusFilter");
      const typeFilter = localStorage.getItem("typeFilter");

      let url = "project-dashboard?";
      let projUrl = "project-dashboard?projectStatusCodes=OA";
      if (statusFilter) {
        url += "projectStatusCodes=" + statusFilter;
        projUrl += "projectStatusCodes=" + statusFilter;
      }
      if (typeFilter) {
        if (statusFilter) {
          url += "&";
          projUrl += "&";
        }
        url += "projectType=" + typeFilter;
        projUrl += "projectType=" + typeFilter;
      }

      if (sDate && eDate) {
        if (typeFilter || statusFilter) {
          url += "&";
          projUrl += "&";
        }
        url += "startDate=" + sDate + "&endDate=" + eDate;
        projUrl += "startDate=" + sDate + "&endDate=" + eDate;
      }

      const response = await axiosPrivate.get(url, {
        headers: {
          signal: controller.signal,
        },
      });

      setData(response.data);
      let emgData = response.data.countryDistribution;

      const proj_response = await axiosPrivate.get(url, {
        headers: {
          signal: controller.signal,
        },
      });

      let countryData = [];
      let projectData = proj_response.data;
      emgData.map((element) => {
        let country = {
          donorName: element.donorName,
          totalBudget: element.totalBudget,
          totalDelivery: element.totalDelivery,
          project: element.projectList[0],
        };

        countryData.push(country);
      });

      setCountryDataList(countryData);

      setProjects(proj_response.data);
      setDistrictData(createDistrictData(response.data.districtDistribution));

      setMapData(getMapData(response.data.budgetMap));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageLayout>
      <DashboardNavbar />
      <Card>
        <MDBox py={1} px={2} display="flex">
          <MDBox display="flex" alignItems="flex-start" lineHeight={0}>
            <FormControlLabel
              control={
                <Switch
                  checked={checkedTcp}
                  onChange={(event) => setCheckedTcp(event.target.checked)}
                  value="FAO"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="FAO"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={checkedEmergency}
                  onChange={(event) => setCheckedEmergency(event.target.checked)}
                  value="EMERGENCY"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Emergency"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={checkedOtherDevProjects}
                  onChange={(event) => setCheckedOtherDevProjects(event.target.checked)}
                  value="OtherDevProjects"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Development Projects"
            />
          </MDBox>
          <OZDivider />
          <MDBox
            display="flex"
            style={{ justifyContent: "flex-end", alignItems: "center" }}
            lineHeight={0}
          >
            {/* <FormControlLabel
              control={
                <Switch
                  checked={checkedOa}
                  onChange={(event) => setCheckedOa(event.target.checked)}
                  value="OA"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Operationally Active"
            /> */}
            <Typography fontWeight="medium" fontSize={"12px"}>
              Operationally Active
            </Typography>
            {/* <FormControlLabel
              control={
                <Switch
                  checked={checkedAc}
                  onChange={(event) => setCheckedAc(event.target.checked)}
                  value="AC"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Activity Completed"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={checkedOc}
                  onChange={(event) => setCheckedOc(event.target.checked)}
                  value="OC"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Operationally Closed"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={checkedFc}
                  onChange={(event) => setCheckedFc(event.target.checked)}
                  value="FC"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Financially Closed"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={checkedPipelined}
                  onChange={(event) => setCheckedPipelined(event.target.checked)}
                  value="P1,P2,P3"
                  style={{
                    color: "blue",
                  }}
                />
              }
              label="Pipeline"
            /> */}
            <MDButton
              sx={{ marginLeft: "20px" }}
              variant="outlined"
              color="info"
              onClick={() => onfilterData()}
            >
              <Typography variant="button" fontWeight="medium">
                Filter
              </Typography>
            </MDButton>
            &nbsp; &nbsp;
            {/* <MDButton variant="outlined" color="info" onClick={() => ClearFilterData(null, null)}>
              <Typography variant="button" fontWeight="medium">
                Clear
              </Typography>
            </MDButton> */}
          </MDBox>
          &nbsp; &nbsp;
          {/* <MDBox
            display="flex"
            style={{ justifyContent: "flex-end", alignItems: "center" }}
            lineHeight={0}
          >
            <Box sx={{ width: 300 }}>
              <Slider
                getAriaLabel={() => "Temperature range"}
                value={value}
                onChangeCommitted={handleChange}
                valueLabelDisplay="on"
                getAriaValueText={valuetext}
                min={1987}
                max={2024}
                step={1}
              />
            </Box>
          </MDBox> */}
          {/* <OZDivider /> */}
          <MDBox lineHeight={0}>
            <MDButton
              variant="outlined"
              color="info"
              onClick={() => navigate("/country-progam-all-filter")}
            >
              <Typography variant="button" fontWeight="medium">
                All projects
              </Typography>
            </MDButton>
          </MDBox>
        </MDBox>
      </Card>
      <MDBox py={3} mt={1} px={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="warning"
                icon="wallet"
                title="Total Budget"
                count={data.totalEmergencyBudget}
                loading={loading}
                fromYear="2022"
                toYear="To Date"
                percentage={false}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="wallet"
                title="Total Delivery"
                count={data.totalDeliveryBudget}
                loading={loading}
                fromYear="2022"
                toYear="To Date"
                percentage={false}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="wallet"
                title="Delivery %"
                count={(data.totalDeliveryBudget / data.totalEmergencyBudget) * 100}
                loading={loading}
                fromYear="2022"
                toYear="To Date"
                percentage={true}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}></Grid>
          <Grid item xs={12} md={6} lg={3}>
            {/* <MDBox mb={1.5}> */}
            <CountContent
              icon={countImg1}
              title="Country projects"
              count={projects?.projects}
              loading={loading}
              linkTo="/country-program-project-list?OA=OA"
            />
            {/* <StaticCard
                icon="wallet"
                color="error"
                title="Projects"
                count={projects?.projects}
                loading={loading}
                fromYear="2022"
                toYear="To Date"
                percentage={false}
                navigateTo="/country-program-project-list"
              /> */}
            {/* </MDBox> */}
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <CountContent
                icon={countImg1}
                title="Regional Projects"
                count={projects?.totalRegionalProjects}
                loading={loading}
                linkTo="/country-program-project-list?RAS=RAS"
              />
              {/* <StaticCard
                icon="wallet"
                color="error"
                title=" Regional Projects"
                count={projects?.totalRegionalProjects}
                loading={loading}
                fromYear="2022"
                toYear="To Date"
                percentage={false}
                navigateTo="/country-program-project-list?RAS=RAS"
              /> */}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <CountContent
                icon={countImg1}
                title="Global Projects"
                count={projects?.totalGlobalProjects}
                loading={loading}
                linkTo="/country-program-project-list?GLO=GLO"
              />
              {/* <StaticCard
                icon="wallet"
                color="error"
                title="Global Projects"
                count={projects?.totalGlobalProjects}
                loading={loading}
                fromYear="2022"
                toYear="To Date"
                percentage={false}
                navigateTo="/country-program-project-list?GLO=GLO"
              /> */}
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <CountContent
                icon={countImg1}
                title="Pipeline Project"
                count={"6"}
                loading={loading}
                linkTo="/country-program-project-list?PIPE=PIPE"
              />
              {/* <StaticCard
                icon="wallet"
                color="error"
                title="Pipeline Project Count"
                count={projects?.projectPipeline}
                loading={loading}
                fromYear="2022"
                toYear="To Date"
                percentage={false}
                navigateTo="/country-program-project-list?PIPE=PIPE"
              /> */}
            </MDBox>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={7}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                {/* {!loading && <FaoDonarBubbleChart countryData={data.countryDistribution} />} */}
                {!loading && (
                  <FaoDonarContribution
                    countryData={countryDataList}
                    showDelivery={false}
                    // onDonorRowClick={onDonorRowClick}
                    // clickedRowIndex={clickedRowIndex}
                    // clearFilterData={clearFilterData}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <DeliverableTypes />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <MDBox mt={6}>
              {!loading && (
                <ReportsLineChart
                  color="success"
                  title="Funding Recieved Progress By Year"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in each year.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={mapData}
                  Tooltip="Budget in USD"
                />
              )}{" "}
            </MDBox>
          </Grid>
        </Grid>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={12}>
            {!loading && <CountryProjectList projects={projects} />}
          </Grid>
        </Grid> */}

        {/* <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3}>
                {!loading && (
                  <ReportsBarChart
                    color="info"
                    title="Delivery Distribution By District"
                    description={
                      <>
                        (<strong>+15%</strong>) increase in each year.
                      </>
                    }
                    date="updated 4 min ago"
                    chart={districtData}
                    Tooltip="Budget in USD"
                  />
                )}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>

      <FAB actions={navActions()} />
    </PageLayout>
  );
}

export default Emergency;
