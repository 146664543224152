import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";

export default function data(countryData) {
  function limit(string = "", limit = 0) {
    if (string.length < limit) return string;

    return string.substring(0, limit - 4) + "...";
  }

  // Format the price above to USD using the locale, style, and currency.
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const rowData = countryData.map((rows) => ({
    Row_Labels: rows[0],
    Sum_of_Commitments_and_Actuals: rows[1],
    Sum_of_Budget: rows[2],
    Sum_of_Available_Budget: rows[3],
    Sum_of_Hard_Commitment: rows[5],
    Sum_of_Actuals: rows[4],
    Sum_of_Soft_Commitment: rows[6],
  }));
  return {
    columns: [
      { Header: "Commitment Type", accessor: "donor", width: "150px", align: "left" },
      { Header: "Sum Of Soft Commitment", accessor: "totalBudget", align: "right" },
      { Header: "Sum of Hard Commitment", accessor: "totalDelivery", align: "right" },
      { Header: "Sum of Actual", accessor: "deliveryPercentage", align: "right" },
      { Header: "Sum of Available Budget", accessor: "SumofAvailableBudget", align: "right" },
      { Header: "Sum of Budget", accessor: "SumofBudget", align: "center" },
    ],

    rows:
      rowData &&
      rowData.map((row) => {
        return {
          donor: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.Row_Labels}
            </MDTypography>
          ),
          totalBudget: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.Sum_of_Soft_Commitment ? USDollar.format(row.Sum_of_Soft_Commitment) : "$0.00"}
            </MDTypography>
          ),
          totalDelivery: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.Sum_of_Hard_Commitment ? USDollar.format(row.Sum_of_Hard_Commitment) : "$0.00"}
            </MDTypography>
          ),
          deliveryPercentage: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.Sum_of_Actuals ? USDollar.format(row.Sum_of_Actuals) : "$0.00"}
            </MDTypography>
          ),
          SumofAvailableBudget: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.Sum_of_Available_Budget ? USDollar.format(row.Sum_of_Available_Budget) : "$0.00"}
            </MDTypography>
          ),
          SumofBudget: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {row.Sum_of_Budget ? USDollar.format(row.Sum_of_Budget) : "$0.00"}
            </MDTypography>
          ),
          actions: (
            <MDButton
              component={Link}
              to={`/donor-profile/${encodeURIComponent(row.donorName)}`}
              color="dark"
            >
              Details
            </MDButton>
          ),
        };
      }),
  };
}
