/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import MDButton from "components/MDButton";

function ProfileInfoImgCard({ info = null, loading }) {
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;

  const [ppaInfoList, setPpaInfoList] = useState([{ code: "", description: "" }]);

  function getDescription(fwCode) {
    switch (fwCode) {
      case "BE1":
        return "Climate change mitigating and adapted agri-food systems";
      case "BE2":
        return "Bioeconomy for sustainable food and agriculture";
      case "BE3":
        return "Biodiversity and ecosystem services for food and agriculture";
      case "BE4":
        return "Achieving sustainable urban food systems";
      case "BP1":
        return "Innovation for sustainable agriculture production";
      case "BP2":
        return "Blue transformation";
      case "BP3":
        return "One Health";
      case "BP4":
        return "Small-Scale Producers’ Equitable Access to Resources";
      case "BP5":
        return "Digital Agriculture";
      case "BN1":
        return "Healthy diets for all";
      case "BN2":
        return "Nutrition for the most vulnerable";
      case "BN3":
        return "Safe food for everyone";
      case "BN4":
        return "Reducing food loss and waste";
      case "BN5":
        return "Transparent markets and trade";
      case "BL1":
        return "Gender equality and rural women’s empowerment";
      case "BL2":
        return "Inclusive rural transformation";
      case "BL3":
        return "Agriculture and food emergencies";
      case "BL4":
        return "Resilient agri-food systems";
      case "BL5":
        return "Hand-in-Hand (HIH) Initiative";
      case "BL6":
        return "Scaling up investment";
      default:
        return "Better Production";
    }
  }

  useEffect(() => {
    let ppaNewList = [];
    info?.ppaList?.split(",")?.forEach((ppa) => {
      ppaNewList.push({ code: ppa, description: getDescription(ppa) });
    });
    setPpaInfoList(ppaNewList);
  }, []);

  // Convert this form `objectKey` of the object key in to this `object key`
  info &&
    Object.keys(info).forEach((el) => {
      if (el.match(/[A-Z\s]+/)) {
        const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
        const newElement = el.replace(uppercaseLetter, ` ${uppercaseLetter.toLowerCase()}`);

        labels.push(newElement);
      } else {
        labels.push(el);
      }
    });

  // Render the card social media icons
  // const renderSocial = social.map(({ link, icon, color }) => (
  //   <MDBox
  //     key={color}
  //     component="a"
  //     href={link}
  //     target="_blank"
  //     rel="noreferrer"
  //     fontSize={size.lg}
  //     color={socialMediaColors[color].main}
  //     pr={1}
  //     pl={0.5}
  //     lineHeight={1}
  //   >
  //     {icon}
  //   </MDBox>
  // ));

  return (
    <Card sx={{ height: "100%", boxShadow: !true && "none" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
          <img
            src={info?.image}
            alt="profile"
            style={{ width: "75px", height: "75px", borderRadius: "50%" }}
          />
        </MDTypography>
        <MDTypography variant="h4" color="secondary">
          {info?.code}
        </MDTypography>
        <MDButton
          component={Link}
          to={`/result/${encodeURIComponent(info.entityNumber)}/type/EF`}
          color="dark"
        >
          Results
        </MDButton>
      </MDBox>
      <MDBox p={2}>
        <MDBox mb={2} lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="light">
            {info.description ? info?.description : ""}
          </MDTypography>
        </MDBox>
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>

        {!loading ? (
          ppaInfoList?.map((ppa, index) => (
            <MDBox
              key={index}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={1}
            >
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {ppa.code}: &nbsp;
              </MDTypography>
              <MDTypography variant="h6" fontWeight="bold" color="text">
                &nbsp;{ppa.description}
              </MDTypography>
            </MDBox>
          ))
        ) : (
          <CircularProgress />
        )}
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoImgCard
ProfileInfoImgCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoImgCard
ProfileInfoImgCard.propTypes = {
  info: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ProfileInfoImgCard;
